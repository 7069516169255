import React from 'react'
import { Typography } from '@material-ui/core'

import { SectionBlock } from '../SectionBlock'

import { useStyles } from './PublishEventsSection.style'

const PublishEventsSection = (sectionProps) => {
  const classes = useStyles()

  return (
    <SectionBlock
      containerClassName={classes.container}
      nextSectionId='usSection'
      {...sectionProps}>
      <div className={classes.mainContainer}>
        <div className={classes.content}>
          <Typography className={classes.title}>
            PUBLICÁ <span>SIN COSTO</span>
            <br />
            TUS EVENTOS EN
            <br />
            UNA <span>PLATAFORMA</span>
            <br />
            DEDICADA
            <br />
            AL <span>DEPORTE.</span>
          </Typography>
        </div>
        <div className={classes.sportImage} />
      </div>
    </SectionBlock>
  )
}

export default PublishEventsSection
