import React from 'react'
import { Button, CircularProgress, Typography } from '@material-ui/core'
import { SectionLayoutDialog } from 'shared'

import { useStyles } from './DeletePaymentConfirmDialog.style'

const DeletePaymentConfirmDialog = ({
  open = false,
  onClose = () => {},
  onDelete = () => {},
  pending
}) => {
  const classes = useStyles()

  return (
    <SectionLayoutDialog
      title='Eliminar comprobante de pago'
      open={open}
      onClose={onClose}
      className={classes.dialog}>
      <div className={classes.mainContainer}>
        <div>
          <Typography variant='h6'>
            Si eliminas el comprobante, notificá al deportista para que suba el comprobante
            correcto.
          </Typography>
          <Typography variant='h6'>Esta acción no se puede deshacer.</Typography>
        </div>
        <div className={classes.buttonContainer}>
          <Button
            variant='outlined'
            color='primary'
            onClick={onClose}
            disabled={pending}
            className={classes.button}>
            Cancelar
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={onDelete}
            className={classes.button}
            disabled={pending}
            endIcon={pending && <CircularProgress color='primary' size={16} />}>
            Eliminar
          </Button>
        </div>
      </div>
    </SectionLayoutDialog>
  )
}

export default DeletePaymentConfirmDialog
