import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  dialog: {
    width: '100% !important',
    maxWidth: '100% !important'
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3)
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    position: 'relative',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      justifyContent: 'space-between',
      flexDirection: 'row',
      margin: 0,
      gap: 0
    }
  },
  receiptTitle: {
    fontWeight: 600
  },
  stateContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(),
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      right: 0,
      top: 0
    }
  },
  stateTitle: {
    fontWeight: 500
  },
  button: {
    minWidth: 40,
    padding: '6px'
  },
  select: {
    width: 100,
    '& svg': {
      color: theme.palette.secondary.main
    },

    '& > div': {
      borderColor: theme.palette.primary.main,
      zIndex: 10,
      borderRadius: 0,
      boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)'
    }
  },
  receiptReviewedButton: {
    backgroundColor: `${theme.utils.rgba(theme.palette.primary.main, 0.4)} !important`,
    color: `${theme.palette.primary.contrastText} !important`
  },
  textAreaEdit: {
    width: '100%',
    '& > div > textArea': {
      minHeight: 150,
      '&::placeholder': {
        color: 'rgba(105, 105, 105, 1)'
      },
      [theme.breakpoints.up('md')]: {
        minHeight: 50
      }
    },
    padding: theme.spacing(2, 0),
    '& fieldset': {
      borderColor: theme.palette.primary.main
    }
  },
  deleteButton: {
    position: 'absolute',
    right: 20,
    minWidth: 35,
    width: 35,
    bottom: 20,
    height: 35,
    [theme.breakpoints.up('md')]: {
      bottom: 30,
      right: 30
    }
  },
  deleteIcon: {
    width: 25,
    height: 25
  },
  progress: {
    '& svg': {
      width: 15,
      height: 15
    }
  },
  fileViewContainer: {
    height: '70vh !important'
  },
  cropper: {
    height: '57vh !important',
    marginTop: '0px !important',
    overflow: 'hidden'
  },
  fileContainer: {
    position: 'relative'
  },
  saveButton: {
    alignSelf: 'center',
    borderRadius: 10,
    width: 200,

    '& span': {
      fontSize: '1rem'
    }
  },
  commentInfo: {
    color: theme.palette.helperText.main,
    flexShrink: 0,
    fontSize: '.75rem'
  },
  commentDescription: {
    overflowWrap: 'anywhere',
    whiteSpace: 'pre-wrap'
  }
}))
