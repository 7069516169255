import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 600,
    padding: theme.spacing(0, 3),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical'
  },
  actionButton: {
    minWidth: 40,
    padding: '6px'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '60%',
    minWidth: 350,
    margin: '0 auto',
    gap: theme.spacing(2)
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  },
  content: {
    backgroundColor: theme.palette.background.default,

    padding: theme.spacing(4, 3),
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing()
  },
  state: {
    padding: theme.spacing(0, 3),
    '& > div': {
      margin: theme.spacing(0, 1),
      width: 18,
      display: 'inline-block',
      marginBottom: -3,
      height: 18
    }
  },
  distanceAndCategory: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical'
  }
}))
