import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Typography, useMediaQuery } from '@material-ui/core'
import clsx from 'clsx'
import { ROUTES } from 'routes'
import {
  loadUpcomingEventsByMyOrganization,
  resetUpcomingEventsByMyOrganization
} from 'state/modules/userAccounts'
import { SECTION_STATES } from 'utils/constants'

import { EventContainer } from '../EventContainer'
import { SectionBlock } from '../SectionBlock'

import { useStyles } from './UpcomingEventsByMyOrganizationSection.style'

const STEP_NUMBER = 1

const UpcomingEventsByMyOrganizationSection = ({ myOrganization, expanded }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const {
    upcomingEventsByMyOrganization,
    upcomingEventsByMyOrganizationPageNumber,
    upcomingEventsByMyOrganizationLastPage
  } = useSelector((state) => state.userAccounts)

  useEffect(() => {
    if (myOrganization) dispatch(loadUpcomingEventsByMyOrganization(myOrganization.id))

    return () => dispatch(resetUpcomingEventsByMyOrganization())
  }, [myOrganization])

  const minForRepeat = isDesktop ? 4 : 1

  return (
    <SectionBlock
      sectionClassName={clsx(expanded && classes.section)}
      title={!isDesktop && 'Próximos eventos de Mi organización'}>
      <div>
        <div className={classes.mainContainer}>
          <div className={classes.block}>
            {isDesktop && (
              <Typography color='primary' variant='h6' className={classes.title}>
                Próximos eventos de Mi organización
              </Typography>
            )}
            {!upcomingEventsByMyOrganization.length && (
              <Typography color='primary' className={classes.emptyText}>
                Tu organización no tiene próximos eventos.
              </Typography>
            )}
          </div>
          <div
            className={clsx(classes.buttonContainer, expanded && SECTION_STATES.EXPANDED_SECTION)}>
            <Button
              variant='contained'
              color='secondary'
              to={`${ROUTES.EVENTS.CREATE}?organizationIdOrHandle=${
                myOrganization.handle || myOrganization.id
              }`}
              component={Link}
              className={classes.button}>
              Crear evento
            </Button>
          </div>
        </div>
        <EventContainer
          events={upcomingEventsByMyOrganization}
          autoplay={true}
          nextPage={upcomingEventsByMyOrganizationPageNumber + STEP_NUMBER}
          lastPage={upcomingEventsByMyOrganizationLastPage}
          infinite={upcomingEventsByMyOrganization.length > minForRepeat}
          fetchNewPage={(page) =>
            dispatch(loadUpcomingEventsByMyOrganization(myOrganization.id, page))
          }
        />
      </div>
    </SectionBlock>
  )
}

export default UpcomingEventsByMyOrganizationSection
