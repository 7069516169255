import { makeStyles, TextField, withStyles } from '@material-ui/core'

export const StyleTextField = withStyles((theme) => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.primary.main
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.dark
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main
      }
    }
  }
}))(TextField)

export const useStyle = makeStyles((theme) => ({
  mainContainer: {
    [theme.breakpoints.up('lg')]: {
      margin: '0 auto',
      width: 1250
    }
  },
  headerContainer: {
    paddingTop: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(6)
    }
  },
  featureImage: {
    height: 200,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    [theme.breakpoints.up('lg')]: {
      margin: '0 auto',
      width: 1200,
      height: 435
    }
  },
  profileImage: {
    marginTop: -(theme.spacing(2) + 75),
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: 75,
    width: 150,
    flexShrink: 0,
    height: 150
  },
  iconImageOrganization: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: 75,
    width: 25,
    height: 25,
    marginRight: 10,
    flexShrink: 0
  },
  containerUsername: {
    [theme.breakpoints.up('lg')]: {
      display: 'flex'
    }
  },
  userAccountInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      flexDirection: 'row',
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(4),

      paddingBottom: theme.spacing(2),
      width: 1170,
      margin: '0 auto'
    }
  },
  titleContainer: {
    padding: theme.spacing(2),
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      textAlign: 'left',
      flexGrow: 1,
      padding: theme.spacing(0),
      marginLeft: theme.spacing(5),
      marginRight: theme.spacing(5)
    }
  },
  fullNameContainer: {
    display: 'flex',
    maxWidth: 350,
    width: 'auto',
    justifyContent: 'space-between'
  },
  title: {
    fontWeight: 500,
    fontSize: '1.1em',
    textTransform: 'capitalize',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    minWidth: '30%',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.5em'
    }
  },
  username: {
    fontWeight: 400,
    fontSize: '1.25em',
    overflow: 'hidden',
    maxWidth: 250,
    textOverflow: 'ellipsis',
    marginTop: theme.spacing(),
    marginBottom: theme.spacing()
  },
  sportsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      alignItems: 'flex-start'
    }
  },
  sport: {
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    fontWeight: 500
  },
  containerActionBar: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  autoComplete: {
    width: 280,
    marginTop: theme.spacing(),
    '& label': {
      color: theme.palette.primary.main,
      zIndex: 0
    }
  },

  actionBar: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    borderTop: '1px solid #D3D3D3',
    borderBottom: '1px solid #D3D3D3',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  linkResult: {
    textDecoration: 'underline',
    fontWeight: 600,
    cursor: 'pointer'
  },
  actionBarButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexGrow: 1,
    [theme.breakpoints.up('lg')]: {
      '& button': {
        marginTop: 0,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
      }
    }
  },
  containerBody: {
    backgroundColor: '#FFFFFF'
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),

    '&.hidden': {
      overflow: 'hidden'
    },

    [theme.breakpoints.up('md')]: {
      minHeight: '75vh',
      flexDirection: 'row',
      gap: 0
    }
  },
  leftColumn: {
    order: 2,
    margin: theme.spacing(0, 1),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      flexGrow: 1,
      order: 1,
      gap: 0,
      margin: 0,
      width: '77%',
      '&.hidden': {
        overflow: 'hidden'
      }
    }
  },
  rightColumn: {
    order: 1,
    position: 'relative',
    zIndex: 2,
    margin: theme.spacing(0, 1),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),

    '&.alt': {
      '& > div > div > div > div > div > div': {
        borderRadius: 0
      }
    },

    [theme.breakpoints.up('md')]: {
      transition: 'all .3s',
      width: '33%',
      gap: 0,
      flexShrink: 0,
      borderLeft: '1px solid #A9ABAE',
      alignItems: 'flex-start',
      borderBottom: 'none !important',
      order: 2,
      margin: 0,
      visibility: 'visible',
      transform: 'translateX(0)',
      '&.hidden': {
        transform: 'translateX(65%)',
        borderColor: theme.palette.primary.main
      }
    }
  },

  infoLabel: {
    fontSize: '1.25rem',
    fontWeight: 600
  },
  infoTitle: {
    fontSize: '.8rem',
    textTransform: 'uppercase',
    fontWeight: 600,
    marginTop: theme.spacing(1)
  },
  infoText: {
    fontSize: '.85rem',
    fontWeight: 400
  },

  socialLinks: {
    display: 'flex',
    flexDirection: 'row'
  },
  containerFollowed: {
    display: 'flex',
    flexDirection: 'row',
    textDecoration: 'none',
    alignItems: 'center',
    marginTop: theme.spacing(2)
  },
  image: {
    width: 62,
    height: 62,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    borderRadius: '50%',
    border: '1px solid #C8C8C8'
  },
  nameContainer: {
    flexGrow: 1,
    paddingLeft: theme.spacing(2)
  },
  name: {
    fontSize: '1.125em',
    fontWeight: 600
  },
  buttonFollow: {
    '&:disabled': {
      color: theme.palette.primary.main,
      backgroundColor: '#FFFFFF'
    }
  },
  buttonFollowing: {
    '&:disabled': {
      color: '#FFFFFF',
      backgroundColor: theme.palette.primary.main
    }
  },
  asyncSelect: {
    marginBottom: theme.spacing(),
    minWidth: 240,
    [theme.breakpoints.up('lg')]: {
      marginRight: theme.spacing(2)
    },
    '& > div > div > div': {
      color: theme.palette.primary.main
    }
  },
  informationContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      alignItems: 'center'
    }
  },
  popUpContainer: {
    zIndex: 1
  },
  inputEdit: {
    marginRight: theme.spacing()
  },
  buttonOpenEdit: {
    width: 285,
    height: 43,
    borderRadius: 20,
    border: `1px solid ${theme.palette.primary.main}`,
    '& span': {
      fontSize: 20,
      fontWeight: 700
    }
  },
  drawer: {
    position: 'relative',
    flexShrink: 0,
    zIndex: 2
  },
  drawerSection: {
    '& > div': {
      right: 0,
      left: 0,
      position: 'relative !important',
      border: 'none'
    }
  },
  button: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      backgroundColor: theme.palette.alt.main,
      color: theme.palette.primary.main,
      display: 'block',
      position: 'absolute',
      left: -15,
      top: 183,
      zIndex: 1201,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
      }
    }
  },
  tooltip: {
    color: theme.palette.secondary.contrastText,
    fontSize: '.75rem',
    backgroundColor: theme.palette.alt.main,
    width: 70,
    maxWidth: 70,
    borderRadius: 0,
    padding: 5,
    margin: theme.spacing(2, 0),

    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(3, 0)
    }
  },
  arrowTooltip: {
    color: theme.palette.alt.main
  },
  tooltipPopper: {
    zIndex: 1000
  }
}))
