import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  disabledTitle: {
    color: theme.palette.disabled.main,
    borderColor: theme.palette.disabled.main
  },
  emptyMessage: {
    fontWeight: 600,
    fontSize: '.875rem',
    whiteSpace: 'pre-wrap'
  },
  ticketContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5)
  },
  ticketInfo: {
    backgroundColor: theme.palette.primary.contrastText,
    display: 'block',
    padding: theme.spacing(0.5, 1),
    boxShadow: '0px 4px 4px 0px #00000040',
    width: '100%',
    marginBottom: theme.spacing(0.5),
    overflowWrap: 'anywhere',
    whiteSpace: 'pre-wrap',
    fontWeight: 500
  },
  quota: {
    color: theme.palette.error.main,
    display: 'inline-block',
    fontWeight: 600,
    padding: theme.spacing(0, 1),
    backgroundColor: theme.palette.background.default
  },
  helperText: {
    fontWeight: 600,
    marginTop: theme.spacing(),
    overflowWrap: 'anywhere',
    whiteSpace: 'pre-wrap'
  },
  optionsContainer: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(2)
  },
  freeEventLabel: {
    fontWeight: 600,
    marginTop: theme.spacing(2)
  }
}))
