import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from '@material-ui/core'
import {
  ArrowBack as ArrowBackIcon,
  Close as CloseIcon,
  Info as InfoIcon
} from '@material-ui/icons'
import { KeyboardDatePicker } from '@material-ui/pickers'
import clsx from 'clsx'
import { Formik } from 'formik'
import moment from 'moment'
import { CountriesProxy } from 'services'
import {
  CustomizedTooltip,
  CustomTextField,
  EllipseStatus,
  FormNotifyDialog,
  PrivacyPolicyContent,
  TermsAndConditionsContent
} from 'shared'
import { StyledSelect } from 'shared/EditableMultipleSelection/EditableMultipleSelection.style'
import { TermsAndConditionsDialog } from 'shared/TermsAndConditionsDialog'
import { downloadRulesDocument } from 'state/modules/events'
import { Transition } from 'utils'
import {
  filterAndSortCategories,
  filterCities,
  filterProvinces,
  getCountries
} from 'utils/functions'
import { showSnackbarError } from 'utils/snackbar'
import * as Yup from 'yup'

import { CustomField } from '../CustomField'
import { SectionBlock } from '../SectionBlock'

import { useStyles } from './InscriptionFormDialog.style'

const SALE_ORDER_STATES = {
  PAID: 'Paid',
  CANCELLED: 'Cancelled',
  PENDING: 'Pending',
  EXPIRED: 'Expired'
}

const INSCRIPTION_STATES = {
  PENDING: 'Pending',
  ACCEPTED: 'Accepted',
  CANCELLED: 'Cancelled'
}

const observerOptions = [
  {
    value: INSCRIPTION_STATES.PENDING,
    label: <EllipseStatus status='yellow' title='Preinscripto' />
  },
  {
    value: INSCRIPTION_STATES.ACCEPTED,
    label: <EllipseStatus status='green' title='Inscripto' />
  },
  {
    value: INSCRIPTION_STATES.CANCELLED,
    label: <EllipseStatus status='red' title='Anulado' />
  },
  {
    value: SALE_ORDER_STATES.EXPIRED,
    label: <EllipseStatus status='grey' title='Vencido' />,
    optionHidden: true
  }
]

const genderList = [
  {
    label: 'Femenino',
    value: 'Female'
  },
  { label: 'Masculino', value: 'Male' },

  { label: 'No binario', value: 'NonBinary' }
]

const OTHER_OPTION = { label: 'Otra', value: 'other' }

const InscriptionFormDialog = ({ open, onClose, title, data, formData = {}, onSubmit }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [provinces, setProvinces] = useState([])
  const [cities, setCities] = useState([])
  const [categories, setCategories] = useState([])
  const [openPolicy, setOpenPolicy] = useState(false)
  const [openTerms, setOpenTerms] = useState(false)
  const [openNotifyErrorsDialog, setOpenNotifyErrorsDialog] = useState(false)

  const { event, downloadRulesDocumentPending } = useSelector((state) => state.events)

  const showNonBinaryOption = !!formData.items?.find(
    ({ eventCategory }) => eventCategory.gender === 'Other' || eventCategory.gender === 'NonBinary'
  )

  const formErrorDictionary = {
    firstName: { label: 'Nombre', order: 1 },
    lastName: { label: 'Apellido', order: 2 },
    idNumber: { label: 'N° de DNI', order: 3 },
    gender: { label: 'Género', order: 4 },
    birthdate: { label: 'Fecha de nacimiento', order: 5 },
    country: { label: 'País', order: 6 },
    areaLevel1: { label: 'Provincia', order: 7 },
    areaLevel2Option: { label: 'Ciudad', order: 8 },
    eMail: { label: 'E-mail', order: 9 },
    phone: { label: 'Teléfono', order: 10 },
    distance: { label: 'Distancia', order: 11 },
    category: { label: 'Categoría', order: 12 },

    ...formData.customFields?.reduce(
      (acc, e, index) => ({
        ...acc,
        [`customFields[${index}]`]: { label: e.name, order: 13 + index }
      }),
      {}
    ),

    termsAndConditions: {
      label: 'Términos y condiciones',
      order: 13 + formData.customFields?.length
    }
  }

  const getErrorsList = (errors) => {
    const termsAndConditions = errors.acceptedTermsAndConditions || errors.acceptedEventRules
    const areaLevel2Option = errors.areaLevel2 || errors.areaLevel2Other

    return Object.keys({
      ...errors,

      ...errors.customFieldValues?.reduce(
        (acc, fieldValue, index) =>
          fieldValue
            ? {
                ...acc,
                [`customFields[${index}]`]: fieldValue
              }
            : acc,
        {}
      ),
      ...(areaLevel2Option ? { areaLevel2Option } : {}),
      ...(termsAndConditions ? { termsAndConditions } : {})
    })
      .map((x) => formErrorDictionary[x])
      .sort((a, b) => a?.order - b?.order)
  }

  const resetCountry = useCallback(() => {
    if (open) {
      onCountryChange()(data.country)
      onProvinceChange()(data.areaLevel1)
    }
  }, [data, open])

  useEffect(() => {
    updateCategories(data.distance.value, data.gender, data.birthdate, formData)
  }, [formData, data])

  useEffect(() => {
    resetCountry()
  }, [resetCountry])

  const handleClickOpenPolicy = (e) => {
    e.preventDefault()
    setOpenPolicy(true)
  }
  const handleClosePolicy = () => setOpenPolicy(false)

  const handleClickOpenTerms = (e) => {
    e.preventDefault()
    setOpenTerms(true)
  }
  const handleCloseTerms = () => setOpenTerms(false)

  const filterGenderList = async (inputValue) =>
    genderList.filter(({ label }) => label.toLowerCase().includes(inputValue.toLowerCase()))

  const onCountryChange = (setValues) => async (options) => {
    try {
      setProvinces([])
      setCities([])

      if (setValues) {
        setValues((values) => {
          const resetUpdateProps =
            values.country.value !== options.value
              ? { areaLevel1: '', areaLevel2: '', areaLevel2Other: '' }
              : null

          return {
            ...values,
            ...resetUpdateProps,
            country: options
          }
        })
      }

      const proxy = new CountriesProxy()

      if (options.value) {
        const data = await proxy.getAreaLevel1(options.value)
        if (Array.isArray(data) && !!data.length) {
          setProvinces(data.map((d) => ({ value: d.id, label: d.name })))
        }
      }
    } catch (e) {
      showSnackbarError({ message: e })
    }
  }

  const onProvinceChange = (setValues) => async (options) => {
    setCities([])

    if (setValues) {
      setValues((values) => {
        const resetUpdateProps =
          values.areaLevel1.value !== options.value ? { areaLevel2: '', areaLevel2Other: '' } : null

        return { ...values, ...resetUpdateProps, areaLevel1: options }
      })
    }

    const proxy = new CountriesProxy()

    if (options.value) {
      const data = await proxy.getAreaLevel2(options.value)
      if (Array.isArray(data) && !!data.length) {
        setCities([...data.map((d) => ({ value: d.id, label: d.name })), OTHER_OPTION])
      }
    }
  }

  const onCityChange = (setValues) => async (options) =>
    setValues((values) => ({ ...values, areaLevel2: options, areaLevel2Other: '' }))

  const updateCategories = (distance, gender, birthdate) => {
    const years =
      moment(birthdate).isValid() && moment(formData.validateAgeFrom).diff(birthdate, 'years')

    setCategories(
      filterAndSortCategories(distance, gender, years, formData).map(
        ({ eventCategory, eventCategoryId, state }) => ({
          label: eventCategory.name,
          value: eventCategoryId,
          state
        })
      )
    )
  }

  const onDistanceChange = (setFieldValue, values) => (options) => {
    if (values.distance.value !== options.value) {
      setFieldValue('category', '')
    }
    setFieldValue('distance', options)
    updateCategories(options.value, values.gender.value, values.birthdate)
  }

  const handleChangeGender = (values, setValues) => (gender) => {
    const category = values.gender.value === gender.value ? values.category : ''

    setValues({ ...values, category, gender })

    updateCategories(values.distance.value, gender.value, values.birthdate)
  }

  const requiredProvince = (country) => country && country.value === event?.countryId

  const getStatusInscription = (state) => observerOptions.find((x) => x.value === state)

  const onChangeStatus = (setFieldValue) => async (options) => setFieldValue('state', options.value)

  const resetDistancesValues = () =>
    formData?.items
      .map((item) => ({
        label: item.eventDistance.name,
        value: item.eventDistanceId
      }))
      .reduce((acc, item) => {
        if (!acc.find((i) => i.value === item.value)) acc.push(item)
        return acc
      }, [])

  const searchDistance = async (inputValue) => {
    if (Array.isArray(formData.items)) {
      const distances = resetDistancesValues()
      return distances.filter((d) => d.label.toLowerCase().includes(inputValue.toLowerCase()))
    }
  }

  const searchCategory = async (inputValue) => {
    if (Array.isArray(categories)) {
      return categories.filter((c) => c.label.toLowerCase().includes(inputValue.toLowerCase()))
    }
  }

  const handleDownloadRulesDocument = (e) => {
    if (event.rulesDocumentFile) {
      e.preventDefault()
      dispatch(downloadRulesDocument(event))
    }
  }

  const handleChangeBirthdate = (setValues, values) => (e) => {
    setValues({ ...values, birthdate: e, category: '' })
    updateCategories(values.distance.value, values.gender.value, e)
  }

  const formValidate = (handleSubmit, isValid) => (e) => {
    e.preventDefault()

    if (isValid) handleSubmit(e)
    else setOpenNotifyErrorsDialog(true)
  }

  const values = {
    ...data,
    gender: genderList.find((e) => e.value === data.gender),
    areaLevel2Other: data.areaLevel2Other || '',
    comment: data.comment || '',
    state:
      data.state === INSCRIPTION_STATES.CANCELLED &&
      data.saleOrder?.state === SALE_ORDER_STATES.EXPIRED
        ? SALE_ORDER_STATES.EXPIRED
        : data.state
  }

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        firstName: Yup.string()
          .max(256, 'El nombre es demasiado largo')
          .required('Ingrese su nombre')
          .trim(),
        acceptedTermsAndConditions: Yup.boolean().oneOf(
          [true],
          'Debe aceptar los términos y condiciones'
        ),
        country: Yup.mixed().required('Seleccione un país'),
        areaLevel1: Yup.mixed().when('country', {
          is: (country) => country?.value === event?.countryId,
          then: Yup.mixed().required('Seleccione una provincia')
        }),
        areaLevel2: Yup.mixed().when('country', {
          is: (country) => country?.value === event?.countryId,
          then: Yup.mixed().required('Seleccione una ciudad')
        }),
        acceptedEventRules: Yup.boolean().oneOf([true], 'Debe aceptar las reglas del evento'),
        lastName: Yup.string()
          .max(256, 'El apellido es demasiado largo')
          .required('Ingrese su apellido')
          .trim(),
        eMail: Yup.string()
          .min(5, 'El e-mail es demasiado corto')
          .email('El e-mail no es válido')
          .max(254, 'El e-mail es demasiado largo')
          .required('Debe ingresar el e-mail'),

        gender: Yup.object().nullable().required('Debe elegir su género'),
        idNumber: Yup.string()
          .required('Debe ingresar su DNI')
          .min(6, 'El DNI debe poseer como mínimo 6 caracteres')
          .max(15, 'El DNI debe poseer como máximo 15 caracteres')
          .matches(/^[a-z0-9]+$/i, 'El DNI no debe tener caracteres especiales ni espacios'),
        birthdate: Yup.string()
          .nullable()
          .required('Seleccione su fecha de nacimiento')
          .test(
            'test-date',
            'No se admiten fechas futuras',
            (value) => !!value && moment(value).isBefore()
          )
          .test(
            'test-age',
            'La edad permitida es entre 3 y 120 años',
            (value) =>
              !!value && moment().diff(value, 'years') >= 3 && moment().diff(value, 'years') <= 120
          ),
        category: Yup.object().required('Debe elegir una categoría'),
        distance: Yup.object().required('Debe elegir una distancia'),
        phone: Yup.string()
          .nullable()
          .required('Debe ingresar un número de teléfono')
          .min(5, 'El número de teléfono debe tener al menos 5 caracteres')
          .max(20, 'El número de teléfono no puede tener más de 20 caracteres')
          .matches(/^[+]*(?:[0-9]?){5,20}[0-9]$/, 'El número de teléfono ingresado no es válido'),
        areaLevel2Other: Yup.string()
          .nullable()
          .when(['areaLevel2', 'country'], (areaLevel2, country, schema) =>
            areaLevel2?.value === OTHER_OPTION.value && country?.value === event?.countryId
              ? schema.trim().required('Debe ingresar el nombre de su ciudad')
              : schema
          ),
        customFieldValues: Yup.array().of(
          Yup.object().shape({
            fieldValue: Yup.mixed()
              .when('eventCustomField', (eventCustomField, schema) => {
                switch (eventCustomField.type) {
                  case 'Text':
                  case 'File':
                  case 'List':
                  case 'Boolean':
                    return Yup.string().nullable().trim()

                  case 'Number':
                    return Yup.number()
                      .typeError('Ingrese un número válido')
                      .max(999999999999999, 'Se puede ingresar hasta 15 dígitos')

                  default:
                    return schema
                }
              })
              .when('eventCustomField', (eventCustomField, schema) =>
                eventCustomField.isRequired ? schema.required('Este campo es requerido') : schema
              )
          })
        )
      }),
    [event]
  )

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      BackdropProps={{
        classes: {
          root: classes.backDrop
        }
      }}
      fullScreen
      PaperProps={{
        classes: {
          root: classes.dialog
        }
      }}>
      <div className={classes.headerContainer}>
        <div className={classes.titleContainer}>
          <Typography variant='h5' color='primary' className={classes.title} title={title}>
            {title}
          </Typography>
        </div>
        <IconButton onClick={onClose} color='secondary'>
          <CloseIcon fontSize='large' />
        </IconButton>
      </div>
      <Formik
        initialValues={values}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={onSubmit}>
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValid,
          setFieldValue,
          setValues
        }) => {
          const validationProvince = requiredProvince(values.country)
          const showOtherOption = values.areaLevel2.value === OTHER_OPTION.value
          return (
            <form onSubmit={handleSubmit}>
              <div className={classes.observationContainer}>
                <div className={classes.observation}>
                  <div className={clsx(classes.formGroup, classes.observationSelectFormGroup)}>
                    <Typography color='primary' variant='h5'>
                      Estado
                    </Typography>

                    <StyledSelect
                      value={getStatusInscription(values.state)}
                      defaultOptions={observerOptions}
                      colorOptions='primary'
                      filterOption={(option) =>
                        !option.data.optionHidden && values.state !== option.value
                      }
                      isSearchable={false}
                      onChange={onChangeStatus(setFieldValue)}
                      className={classes.select}
                    />
                  </div>
                  <div className={clsx(classes.observationTextFieldContainer, classes.formGroup)}>
                    <Typography color='primary' variant='h5'>
                      Observaciones de inscripción
                    </Typography>
                    <TextField
                      variant='outlined'
                      placeholder='Escriba aquí las observaciones que sean necesarias.'
                      className={classes.textAreaEdit}
                      color='primary'
                      name='comment'
                      multiline
                      onChange={handleChange}
                      value={values.comment}
                    />
                  </div>
                </div>
              </div>
              <div className={classes.sectionsContainer}>
                <SectionBlock>
                  <div className={classes.formGroup}>
                    <Typography color='primary' variant='h6'>
                      Nombre *
                    </Typography>
                    <TextField
                      variant='outlined'
                      size='small'
                      name='firstName'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={!!errors.firstName && touched.firstName}
                      helperText={errors.firstName}
                      fullWidth
                      value={values.firstName}
                      placeholder='Ingrese su nombre'
                      autoComplete='off'
                    />
                  </div>
                  <div className={classes.formGroup}>
                    <Typography color='primary' variant='h6'>
                      Apellido *
                    </Typography>
                    <TextField
                      variant='outlined'
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!errors.lastName && touched.lastName}
                      helperText={errors.lastName}
                      placeholder='Ingrese su apellido'
                      size='small'
                      name='lastName'
                      fullWidth
                      autoComplete='off'
                    />
                  </div>
                  <div className={classes.separatorContainer}>
                    <div className={classes.formGroupContainer}>
                      <div className={classes.formGroup}>
                        <Typography color='primary' variant='h6'>
                          N° de DNI *
                        </Typography>
                        <TextField
                          variant='outlined'
                          size='small'
                          onBlur={handleBlur}
                          error={!!errors.idNumber && touched.idNumber}
                          helperText={errors.idNumber}
                          onChange={handleChange}
                          placeholder='********'
                          value={values.idNumber}
                          name='idNumber'
                          fullWidth
                          autoComplete='off'
                        />
                      </div>
                      <div className={clsx(classes.fieldContainer, classes.formGroup)}>
                        <Typography color='primary' variant='h6'>
                          Género *
                        </Typography>
                        <StyledSelect
                          cacheOptions
                          defaultOptions={genderList}
                          className={classes.select}
                          defaultValue={values.gender}
                          onBlur={handleBlur}
                          loadOptions={filterGenderList}
                          name='gender'
                          placeholder='Seleccione una opción'
                          colorOptions='secondary'
                          onChange={handleChangeGender(values, setValues)}
                          filterOption={(option) =>
                            option.value !== 'NonBinary' || showNonBinaryOption
                          }
                        />
                        {errors.gender && (
                          <Typography
                            variant='caption'
                            color={touched.gender ? 'error' : 'initial'}>
                            {errors.gender}
                          </Typography>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={classes.formGroupContainer}>
                    <div className={classes.formGroup}>
                      <Typography color='primary' variant='h6'>
                        Fecha de nacimiento *
                      </Typography>
                      <KeyboardDatePicker
                        className={`${classes.input} datePicker`}
                        format='DD/MM/YYYY'
                        autoOk
                        variant='inline'
                        name='birthdate'
                        onBlur={handleBlur}
                        margin='none'
                        id='date-picker-inline'
                        inputVariant='outlined'
                        error={!!errors.birthdate && touched.birthdate}
                        helperText={errors.birthdate}
                        onChange={handleChangeBirthdate(setValues, values)}
                        style={{ width: '100%' }}
                        value={values.birthdate}
                        KeyboardButtonProps={{
                          'aria-label': 'seleccione fecha'
                        }}
                        placeholder='ddmmaaaa'
                      />
                    </div>
                    <div className={clsx(classes.fieldContainer, classes.formGroup)}>
                      <Typography color='primary' variant='h6'>
                        País *
                      </Typography>
                      <StyledSelect
                        cacheOptions
                        defaultOptions
                        className={classes.select}
                        value={values.country}
                        onBlur={handleBlur}
                        loadOptions={getCountries()}
                        name='country'
                        placeholder='Seleccione su país'
                        colorOptions='secondary'
                        onChange={onCountryChange(setValues)}
                      />
                      {errors.country && (
                        <Typography variant='caption' color={touched.country ? 'error' : 'initial'}>
                          {errors.country}
                        </Typography>
                      )}
                    </div>
                  </div>
                  <div className={classes.formGroupContainer}>
                    {validationProvince && (
                      <>
                        <div className={clsx(classes.formGroup, classes.fieldContainer)}>
                          <Typography variant='h6' color='primary'>
                            Provincia *
                          </Typography>
                          <StyledSelect
                            cacheOptions
                            className={classes.select}
                            value={values.areaLevel1}
                            defaultOptions={provinces}
                            placeholder='Seleccione su provincia'
                            loadOptions={filterProvinces(provinces)}
                            onBlur={handleBlur}
                            colorOptions='secondary'
                            name='areaLevel1'
                            onChange={onProvinceChange(setValues)}
                          />
                          {errors.areaLevel1 && (
                            <Typography
                              variant='caption'
                              color={touched.areaLevel1 ? 'error' : 'initial'}>
                              {errors.areaLevel1}
                            </Typography>
                          )}
                        </div>
                        {values.areaLevel1 && (
                          <div className={classes.citiesContainer}>
                            <div
                              className={clsx(
                                classes.formGroup,
                                classes.fieldContainer,
                                showOtherOption && classes.optionOtherContainer
                              )}>
                              <Typography variant='h6' color='primary'>
                                Ciudad de Residencia *
                              </Typography>
                              <StyledSelect
                                cacheOptions
                                className={classes.select}
                                value={values.areaLevel2}
                                defaultOptions={cities}
                                placeholder='Seleccione su ciudad'
                                loadOptions={filterCities(cities)}
                                onBlur={handleBlur}
                                colorOptions='secondary'
                                name='areaLevel2'
                                onChange={onCityChange(setValues)}
                              />
                              {errors.areaLevel2 && (
                                <Typography
                                  variant='caption'
                                  color={touched.areaLevel2 ? 'error' : 'initial'}>
                                  {errors.areaLevel2}
                                </Typography>
                              )}
                            </div>
                            {showOtherOption && (
                              <div className={clsx(classes.formGroup, classes.otherOptionText)}>
                                <TextField
                                  variant='outlined'
                                  color='primary'
                                  size='small'
                                  autoComplete='off'
                                  fullWidth
                                  onChange={handleChange}
                                  name='areaLevel2Other'
                                  placeholder='Ingrese su ciudad'
                                  onBlur={handleBlur}
                                  errors={touched.areaLevel2Other && !!errors.areaLevel2Other}
                                  helperText={errors.areaLevel2Other}
                                  value={values.areaLevel2Other}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <div className={classes.formGroupContainer}>
                    <div className={classes.formGroup}>
                      <Typography color='primary' variant='h6'>
                        E-mail *
                      </Typography>
                      <TextField
                        variant='outlined'
                        size='small'
                        value={values.eMail}
                        name='eMail'
                        placeholder='martindelpotrozena@gmail.com'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors.eMail && touched.eMail}
                        helperText={errors.eMail}
                        fullWidth
                        autoComplete='off'
                      />
                    </div>
                    <div className={classes.formGroup}>
                      <Typography color='primary' variant='h6'>
                        Teléfono *
                      </Typography>
                      <CustomTextField
                        variant='outlined'
                        size='small'
                        value={values.phone}
                        onBlur={handleBlur}
                        error={!!errors.phone && touched.phone}
                        helperText={errors.phone}
                        onChange={handleChange}
                        name='phone'
                        fullWidth
                        className={classes.phone}
                        autoComplete='off'
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <CustomizedTooltip
                                position='left-end'
                                arrow
                                popperClassName={classes.popper}
                                title='El Organizador utilizará este número para enviarte información sobre el evento.'>
                                {({ handleTooltip }) => (
                                  <IconButton onClick={handleTooltip}>
                                    <InfoIcon color='primary' />
                                  </IconButton>
                                )}
                              </CustomizedTooltip>
                            </InputAdornment>
                          )
                        }}
                      />
                      <Typography className={classes.helperTextPhone}>
                        Cód. país + cód. de área sin cero + N° tel sin el 15, sin espacios ni
                        guiones.
                      </Typography>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color='primary'
                            checked={values.hasWhatsApp}
                            onChange={(e) => setFieldValue('hasWhatsApp', e.target.checked)}
                            name='hasWhatsApp'
                            onBlur={handleBlur}
                          />
                        }
                        label={<Typography variant='h6'>Este teléfono tiene WhatsApp</Typography>}
                      />
                    </div>
                  </div>
                </SectionBlock>
                <SectionBlock title='Distancia y categoría'>
                  <div className={classes.comboxsContainer}>
                    <div className={classes.fieldContainer}>
                      <StyledSelect
                        cacheOptions
                        placeholder='Seleccione una distancia'
                        name='distance'
                        defaultOptions={resetDistancesValues()}
                        onBlur={handleBlur}
                        onChange={onDistanceChange(setFieldValue, values)}
                        colorOptions='secondary'
                        value={values.distance}
                        className={classes.select}
                        loadOptions={searchDistance}
                      />
                      {errors.distance && (
                        <Typography
                          variant='caption'
                          color={touched.distance ? 'error' : 'initial'}>
                          {errors.distance}
                        </Typography>
                      )}
                    </div>
                    <div className={classes.fieldContainer}>
                      <StyledSelect
                        cacheOptions
                        placeholder='Seleccione una categoría'
                        defaultOptions={categories}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue('categoryId', e.value)
                          setFieldValue('category', e)
                        }}
                        value={values.category}
                        colorOptions='secondary'
                        name='category'
                        className={classes.select}
                        loadOptions={searchCategory}
                      />
                      {errors.category && (
                        <Typography
                          variant='caption'
                          color={touched.category ? 'error' : 'initial'}>
                          {errors.category}
                        </Typography>
                      )}
                    </div>
                  </div>
                </SectionBlock>
                {formData.customFields.map((field, index) => (
                  <SectionBlock
                    key={index}
                    title={`${field.name} ${field.isRequired ? '*' : ''}`}
                    body={field.helperText}>
                    <CustomField
                      {...field}
                      value={
                        values.customFieldValues[index]
                          ? values.customFieldValues[index].fieldValue
                          : ''
                      }
                      error={
                        Array.isArray(errors.customFieldValues)
                          ? errors?.customFieldValues[index]?.fieldValue
                          : ''
                      }
                      touched={
                        Array.isArray(touched.customFieldValues)
                          ? touched?.customFieldValues[index]?.fieldValue
                          : ''
                      }
                      setFieldValue={setFieldValue}
                      handleChange={handleChange}
                      fieldId={`customFieldValues[${index}].eventCustomFieldId`}
                      fieldName={`customFieldValues[${index}].fieldValue`}
                      handleBlur={handleBlur}
                    />
                  </SectionBlock>
                ))}
                <SectionBlock title='Términos y condiciones'>
                  <div className={classes.checkContainer}>
                    {(event.rulesUrl || event.rulesDocumentFile) && (
                      <>
                        <FormControlLabel
                          onChange={handleChange}
                          name='acceptedEventRules'
                          onBlur={handleBlur}
                          control={
                            <Checkbox
                              color='primary'
                              checked={values.acceptedEventRules === true}
                            />
                          }
                          label={
                            <Typography className={classes.tosParagraph}>
                              Acepto el&nbsp;
                              <a
                                href={event.rulesUrl}
                                rel='noopener noreferrer'
                                target='_blank'
                                onClick={handleDownloadRulesDocument}>
                                reglamento del evento.&nbsp;
                                {downloadRulesDocumentPending && (
                                  <CircularProgress size={14} color='inherit' />
                                )}
                              </a>
                            </Typography>
                          }
                        />
                        <Typography variant='caption' color='error'>
                          {errors.acceptedEventRules}
                        </Typography>
                      </>
                    )}
                    <FormControlLabel
                      onChange={handleChange}
                      name='acceptedTermsAndConditions'
                      onBlur={handleBlur}
                      control={
                        <Checkbox
                          color='primary'
                          checked={values.acceptedTermsAndConditions === true}
                        />
                      }
                      label={
                        <Typography variant='body1' className={classes.tosParagraph}>
                          Acepto las&nbsp;
                          <Link to='#' onClick={handleClickOpenTerms}>
                            Condiciones de uso
                          </Link>
                          &nbsp;y la&nbsp;
                          <Link to='#' onClick={handleClickOpenPolicy}>
                            Política de privacidad.
                          </Link>
                        </Typography>
                      }
                    />
                    <TermsAndConditionsDialog
                      handleClose={handleCloseTerms}
                      open={openTerms}
                      content={TermsAndConditionsContent}
                      title='Condiciones de uso'
                    />
                    <TermsAndConditionsDialog
                      handleClose={handleClosePolicy}
                      open={openPolicy}
                      content={PrivacyPolicyContent}
                      title='Política de privacidad'
                    />
                    <Typography variant='caption' color='error'>
                      {errors.acceptedTermsAndConditions}
                    </Typography>
                  </div>
                </SectionBlock>
                <div className={classes.submitContainer}>
                  <IconButton onClick={onClose} className={classes.backButton}>
                    <ArrowBackIcon size='medium' color='primary' />
                  </IconButton>
                  <div className={classes.saveContainer}>
                    <Button
                      color='primary'
                      onClick={formValidate(handleSubmit, isValid)}
                      endIcon={isSubmitting && <CircularProgress color='primary' size={16} />}
                      disabled={isSubmitting}
                      variant='contained'
                      className={clsx(classes.button, classes.saveButton)}>
                      Guardar
                    </Button>
                    <FormNotifyDialog
                      open={openNotifyErrorsDialog}
                      onClose={() => setOpenNotifyErrorsDialog(false)}
                      title='NOTIFICACIÓN FORMULARIO INCOMPLETO'
                      subtitle='Para poder editar la pre-inscripción el formulario debe estar completo y no tener errores, revisa los siguientes campos:'
                      errors={getErrorsList(errors)}
                    />
                  </div>
                </div>
              </div>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}

export default InscriptionFormDialog
