import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  section: {
    display: 'flex',
    flexDirection: 'column',

    '&.expandedSection': {
      [theme.breakpoints.up('md')]: {
        width: 'calc(100% + 261px)'
      },

      [theme.breakpoints.up('lg')]: {
        width: 1230
      }
    }
  },

  mainContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(6),
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      marginBottom: 0,
      minHeight: 145,
      gap: theme.spacing(3)
    }
  },
  title: {
    fontWeight: 700,
    marginTop: theme.spacing(3),
    fontSize: '1.25em',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  block: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      marginBottom: 0
    }
  },
  profileImage: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: 70,
    width: 125,
    flexShrink: 0,
    height: 125
  },
  organizationName: {
    fontSize: '1.25em',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  organizationContainer: {
    display: 'flex',
    gap: theme.spacing(4),
    alignItems: 'center',
    marginTop: -theme.spacing(3),
    cursor: 'pointer',
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(6),
      '&.expandedSection': {
        width: 500
      }
    }
  },
  profileInfo: {
    marginTop: theme.spacing(2),
    width: 'calc(100% - 161px)',
    [theme.breakpoints.up('md')]: {
      maxWidth: 250,

      '&.expandedSection': {
        width: '100%',
        maxWidth: 400
      }
    }
  },

  emptyText: {
    fontWeight: 600,
    fontSize: '.875rem'
  },
  button: {
    borderRadius: 20,
    width: 280,
    height: 40,
    marginRight: theme.spacing(3),
    boxShadow: 'none',
    marginTop: theme.spacing(4),
    alignSelf: 'center',
    '& span': {
      color: theme.palette.primary.main,
      fontWeight: 700,
      fontSize: '1.125rem'
    },
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(6),
      marginTop: theme.spacing(2)
    }
  },
  handle: {
    fontSize: '1.25em',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  headerBlock: {
    flexShrink: 0
  }
}))
