import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { logEvent } from 'firebase/analytics'
import { ForgotPasswordPage, ResetPasswordPage } from 'pages/Auth'
import {
  CreatePage as EventsCreatePage,
  EditPage as EventsEditPage,
  InscriptionFormPage,
  InscriptionPage,
  InscriptionRegisteredListPage,
  LatestResultEventsPage,
  MyCreatedEventsPage,
  MyEventsPage,
  MyLastResultEventsPage,
  MyUpcomingEventsPage,
  ProfilePage as EventsProfilePage,
  SearchPage as EventsSearchPage,
  UpcomingEventsPage
} from 'pages/Events'
import { HomePage } from 'pages/HomePage'
import {
  JobPage,
  OrganizationPage,
  PrivacyPolicyPage,
  TermsAndConditionsPage,
  TimingPage
} from 'pages/Landing'
import {
  CreatePage as OrganizationsCreatePage,
  OnboardingPage as OrganizationsOnboardingPage,
  ProfilePage as OrganizationsProfilePage,
  SearchPage as OrganizationsSearchPage
} from 'pages/Organizations'
import {
  FrequentQuestionsPage as UserAccountsFrequentQuestionsPage,
  ProfilePage as UserAccountsProfilePage,
  SearchPage as UserAccountsSearchPage
} from 'pages/UserAccounts'
import { ROUTES } from 'routes'
import { verifyToken } from 'state/modules/auth'
import { USER_ROLE } from 'utils/constants'
import { analytics } from 'utils/firebase'
import { PrivateRoute } from 'utils/routing'

export const App = () => {
  const { user } = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  const tryLogAnalyticsEvent = () => {
    if (!process.env.REACT_APP_FIREBASE_APP_ID) {
      return
    }

    logEvent(analytics, 'page_view', {
      page_title: document.title,
      page_location: window.location.href
    })
  }

  useEffect(() => {
    tryLogAnalyticsEvent()

    if (!user) {
      dispatch(verifyToken())
    }
  }, [dispatch, user])

  return (
    <Switch>
      <Route exact path={ROUTES.ROOT} component={HomePage} />

      <Route exact path={ROUTES.TERMS_AND_CONDITIONS} component={TermsAndConditionsPage} />

      <Route exact path={ROUTES.PRIVACY_POLICY} component={PrivacyPolicyPage} />

      <Route exact path={ROUTES.FORGOT_PASSWORD} component={ForgotPasswordPage} />

      <Route exact path={`${ROUTES.RESET_PASSWORD}/:token/:userId`} component={ResetPasswordPage} />

      <Route exact path={ROUTES.EVENTS.SEARCH} component={EventsSearchPage} />
      <PrivateRoute
        exact
        path={ROUTES.EVENTS.INSCRIPTION_LIST}
        component={InscriptionRegisteredListPage}
      />
      <Route exact path={`${ROUTES.EVENTS.PROFILE}/:slugUrlOrId`} component={EventsProfilePage} />
      <PrivateRoute
        exact
        path={ROUTES.EVENTS.CREATE}
        component={EventsCreatePage}
        authenticatedProps={{
          verifyRole: true,
          role: USER_ROLE.ORGANIZER
        }}
      />
      <PrivateRoute exact path={ROUTES.EVENTS.MY_EVENTS} component={MyEventsPage} />
      <PrivateRoute
        exact
        path={ROUTES.EVENTS.MY_UPCOMING_EVENTS}
        component={MyUpcomingEventsPage}
      />
      <PrivateRoute
        exact
        path={`${ROUTES.EVENTS.INSCRIPTION}/:slugUrlOrId`}
        component={InscriptionPage}
      />
      <Route exact path={ROUTES.EVENTS.INSCRIPTION_FORM} component={InscriptionFormPage} />

      <Route
        exact
        path={`${ROUTES.EVENTS.INSCRIPTION_FORM}/:eventInscriptionId`}
        component={InscriptionFormPage}
      />

      <PrivateRoute
        exact
        path={ROUTES.EVENTS.MY_LATEST_RESULT_EVENTS}
        component={MyLastResultEventsPage}
      />
      <PrivateRoute exact path={ROUTES.EVENTS.MY_CREATED_EVENTS} component={MyCreatedEventsPage} />
      <Route exact path={ROUTES.EVENTS.UPCOMING_EVENTS} component={UpcomingEventsPage} />
      <Route exact path={ROUTES.EVENTS.LATEST_RESULTS_EVENTS} component={LatestResultEventsPage} />
      <Route
        exact
        path={`${ROUTES.EVENTS.PROFILE}/:slugUrlOrId/:idCircuitCategory`}
        component={EventsProfilePage}
      />

      <PrivateRoute exact path={`${ROUTES.EVENTS.EDIT}/:slugUrlOrId`} component={EventsEditPage} />

      <Route exact path={ROUTES.ORGANIZATIONS.SEARCH} component={OrganizationsSearchPage} />
      <Route
        exact
        path={`${ROUTES.ORGANIZATIONS.PROFILE}/:handleOrId`}
        component={OrganizationsProfilePage}
      />
      <PrivateRoute
        exact
        path={ROUTES.ORGANIZATIONS.CREATE}
        component={OrganizationsCreatePage}
        authenticatedProps={{
          verifyRole: true,
          role: USER_ROLE.ORGANIZER
        }}
      />
      <PrivateRoute
        exact
        path={`${ROUTES.ORGANIZATIONS.ONBOARDING}/:handleOrId`}
        component={OrganizationsOnboardingPage}
        authenticatedProps={{
          verifyRole: true,
          role: USER_ROLE.ORGANIZER
        }}
      />

      <Route
        exact
        path={ROUTES.USERS_ACCOUNTS.FREQUENT_QUESTIONS}
        component={UserAccountsFrequentQuestionsPage}
      />
      <Route exact path={ROUTES.USERS_ACCOUNTS.SEARCH} component={UserAccountsSearchPage} />
      <Route
        exact
        path={`${ROUTES.USERS_ACCOUNTS.PROFILE}/:userAccountId`}
        component={UserAccountsProfilePage}
      />

      <Route exact path={ROUTES.LANDING.TIMING} component={TimingPage} />
      <Route exact path={ROUTES.LANDING.JOB} component={JobPage} />
      <Route exact path={ROUTES.LANDING.ORGANIZATION} component={OrganizationPage} />
    </Switch>
  )
}

export default App
