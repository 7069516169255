import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, Typography, useMediaQuery } from '@material-ui/core'
import organizationNoImage from 'assets/img/no-image.jpg'
import clsx from 'clsx'
import { ROUTES } from 'routes'
import { SECTION_STATES } from 'utils/constants'

import { SectionBlock } from '../SectionBlock'

import { useStyles } from './SectionMyOrganization.style'

const SectionMyOrganization = ({ myOrganization, expanded }) => {
  const classes = useStyles()
  const history = useHistory()

  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const myOrganizationRender = () => {
    const { organization } = myOrganization
    const organizationImageUrl =
      Array.isArray(organization.profileImages) && organization.profileImages.length > 0
        ? organization.profileImages[0]
        : organizationNoImage

    return (
      <div
        className={clsx(classes.organizationContainer, expanded && SECTION_STATES.EXPANDED_SECTION)}
        onClick={() =>
          history.push(`${ROUTES.ORGANIZATIONS.PROFILE}/${organization.handle || organization.id}`)
        }>
        <div
          className={classes.profileImage}
          style={{ backgroundImage: `url(${organizationImageUrl})` }}
        />
        <div className={clsx(classes.profileInfo, expanded && SECTION_STATES.EXPANDED_SECTION)}>
          <Typography
            color='primary'
            variant='h6'
            title={organization.name}
            className={classes.organizationName}>
            {organization.name}
          </Typography>
          <Typography
            color='primary'
            variant='h6'
            className={classes.handle}
            title={`@${organization.handle || organization.id}`}>
            @{organization.handle || organization.id}
          </Typography>
        </div>
      </div>
    )
  }
  return (
    <SectionBlock
      sectionClassName={clsx(classes.section, expanded && SECTION_STATES.EXPANDED_SECTION)}
      title={!isDesktop && 'Mi organización'}>
      <div className={classes.mainContainer}>
        <div className={clsx(classes.block, classes.headerBlock)}>
          {isDesktop && (
            <Typography color='primary' variant='h6' className={classes.title}>
              Mi organización
            </Typography>
          )}
          {!myOrganization && (
            <Typography color='primary' className={classes.emptyText}>
              Aún no has creado tu organización.
            </Typography>
          )}
        </div>

        {!myOrganization ? (
          <Button
            variant='contained'
            color='secondary'
            to={ROUTES.ORGANIZATIONS.CREATE}
            component={Link}
            className={classes.button}>
            Crear organización
          </Button>
        ) : (
          myOrganizationRender()
        )}
      </div>
    </SectionBlock>
  )
}

export default SectionMyOrganization
