import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles((theme) => ({
  container: {
    border: 'none !important',
    [theme.breakpoints.up('md')]: {
      borderBottom: '1px solid #A9ABAE !important'
    }
  },
  section: {
    width: '100%'
  },
  linkFollowed: {
    textDecoration: 'underline',
    fontWeight: 600,
    color: theme.palette.primary.main,
    marginTop: theme.spacing(3),
    fontSize: '.9rem',
    cursor: 'pointer'
  }
}))
