import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  infoTitle: {
    fontSize: '.8rem',
    textTransform: 'uppercase',
    fontWeight: 600,
    marginTop: theme.spacing(1)
  },
  section: {
    width: '100%'
  },
  altSection: {
    display: 'flex',
    flexDirection: 'column'
  },
  infoText: {
    fontSize: '.85rem',
    fontWeight: 400
  },
  socialLinks: {
    display: 'flex',
    flexDirection: 'row'
  },
  profileImage: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: 75,
    width: 150,
    flexShrink: 0,
    height: 150
  },
  formText: {
    marginBottom: theme.spacing(2)
  },
  asyncSelectContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    '& > div > div > div > div': {
      color: theme.palette.primary.main
    }
  },
  datePicker: {
    marginRight: theme.spacing(),
    width: '100%',
    '& input': {
      width: '100%'
    },
    '& fieldset': {
      borderColor: theme.palette.primary.main
    },
    '& > div': {
      paddingRight: 0,
      height: 40
    },
    '& svg': {
      color: '#ffff'
    },
    '& button': {
      borderRadius: 0,
      backgroundColor: theme.palette.primary.main,
      color: '#ffff',
      width: 40,
      height: 40,
      '&:hover': {
        backgroundColor: theme.palette.primary.main
      }
    },
    [theme.breakpoints.up('md')]: {
      width: 250
    }
  }
}))
