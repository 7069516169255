import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    backgroundColor: '#FFFFFF',
    position: 'relative',
    padding: theme.spacing(0, 2),
    minHeight: '100vh',
    paddingTop: `${theme.spacing(15)}px !important`,
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 10),
      paddingTop: `${theme.spacing(20)}px !important`
    },
    [theme.breakpoints.up('lg')]: {
      padding: 0,
      paddingTop: `${theme.spacing(20)}px !important`
    }
  },
  mainContainer: {
    paddingBottom: theme.spacing(9),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      gap: theme.spacing(6),
      paddingBottom: theme.spacing(9)
    },
    [theme.breakpoints.up('lg')]: {
      margin: '0 auto',
      width: 1280
    }
  },
  title: {
    fontWeight: 700,
    fontSize: '1.5rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '2rem'
    }
  },
  content: {
    borderTop: '1px solid #888A8A',

    [theme.breakpoints.up('lg')]: {
      padding: 0
    }
  },
  link: {
    color: theme.palette.primary.main
  },
  list: {
    padding: 0,
    paddingLeft: theme.spacing(2)
  },
  hiddenQuestion: {
    display: 'none'
  }
}))
