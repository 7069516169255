import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  listContainer: {
    position: 'relative',
    zIndex: 1,

    [theme.breakpoints.up('md')]: {
      width: 'calc(100% + 261px)',

      '& #feature-image-container': {
        height: '11.2vw'
      },

      '& #event-card': {
        width: '96%'
      }
    },

    [theme.breakpoints.up('lg')]: {
      width: 1230,
      marginLeft: -theme.spacing(),

      '& #event-card': {
        width: '93%'
      },

      '& #feature-image-container': {
        height: '10.9vw'
      }
    }
  },
  cardContainer: {
    paddingLeft: theme.spacing(4)
  },
  slickSlider: {
    [theme.breakpoints.up('lg')]: {
      '& > div': {
        minWidth: '1000px !important'
      }
    },
    '& > div > div': {
      marginLeft: 0
    }
  }
}))
