import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { IconButton, Typography } from '@material-ui/core'
import {
  Favorite as FavoriteIcon,
  FavoriteBorder as FavoriteBorderIcon,
  LocationOnOutlined as LocationOnOutlinedIcon
} from '@material-ui/icons'
import automovilismo from 'assets/img/automovilismo.jpg'
import biking from 'assets/img/biking.jpg'
import fbi from 'assets/img/fbi.jpg'
import kayak from 'assets/img/kayak.jpg'
import motocross from 'assets/img/motocross.jpg'
import mountainBike from 'assets/img/mountain-bike.jpg'
import natacion from 'assets/img/natacion.jpg'
import running from 'assets/img/running.jpg'
import speedway from 'assets/img/speedway.jpg'
import clsx from 'clsx'
import moment from 'moment'
import { ROUTES } from 'routes'
import { ShareUrlMenuDialog } from 'shared'
import { EventCardPreview } from 'shared/EventCardPreview'
import { verifyUserNotLogged } from 'state/modules/app'
import { reactEvent } from 'state/modules/events'

import { useStyles } from './EventCard.style'

const getDateEventFormat = (fromDate, toDate) => {
  const fromDateFormat = fromDate.format('YYYY-MM-DD')
  const toDateFormat = toDate.format('YYYY-MM-DD')
  const dateFormats = ['DD MMM YY', 'DD MMM', 'DD']

  const toDateResult = toDate.format(dateFormats[0]).replace('.', '')

  if (moment(fromDateFormat).isSame(toDateFormat))
    return fromDate.format(dateFormats[0]).replace('.', '')

  if (moment(fromDateFormat).year() !== moment(toDateFormat).year())
    return `${fromDate.format(dateFormats[0])} - ${toDateResult}`.replace('.', '')

  if (moment(fromDateFormat).month() !== moment(toDateFormat).month())
    return `${fromDate.format(dateFormats[1])}/${toDateResult}`.replace('.', '')
  else return `${fromDate.format(dateFormats[2])}/${toDateResult}`.replace('.', '')
}

const DISCIPLINE_IMAGES = {
  automovilismo,
  biking,
  fbi,
  kayak,
  motocross,
  mountainBike,
  natacion,
  running,
  speedway
}

const EventCard = ({ event, disabledAction, isFinished, hiddenInscriptionButton }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  const [isModalOpen, setIsModalOpen] = useState(false)

  const { user } = useSelector((state) => state.auth)
  const { pendingReact } = useSelector((state) => state.events)

  const {
    id,
    from,
    to,
    disciplines,
    name,
    loggedUserMetadata,
    areaLevel1Name,
    countryName,
    isSuspended,
    likesCount,
    hasResults,
    createdAt,
    updatedAt,
    organizations,
    slugUrl,
    externalInscriptionUrl,
    address,
    longitude,
    latitude,
    circuit,
    schedule,
    profileImages,
    rulesUrl,
    activeInscriptionFormOpenFrom,
    activeInscriptionFormOpenTo,
    activeInscriptionFormId,
    hasRulesDocumentFile
  } = event

  const toLocal = moment(to)
  const fromLocal = moment(from)
  const afterEvent = toLocal < moment()

  const isOrganizer = loggedUserMetadata?.canEdit

  const isActiveInscription =
    !hiddenInscriptionButton &&
    ((!!activeInscriptionFormId &&
      moment(activeInscriptionFormOpenFrom).isSameOrBefore(moment()) &&
      moment(activeInscriptionFormOpenTo).isSameOrAfter(moment())) ||
      externalInscriptionUrl)
  const hasActiveSchedule = schedule.some(
    (x) =>
      fromLocal.isSameOrBefore(x.scheduleDate) &&
      toLocal.isSameOrAfter(x.scheduleDate) &&
      !!x.description
  )

  const hasMoreInformation =
    isActiveInscription ||
    !!externalInscriptionUrl ||
    isOrganizer ||
    hasResults ||
    hasActiveSchedule ||
    hasRulesDocumentFile ||
    !!circuit ||
    !!rulesUrl

  const countDisciplines = disciplines.length - 1
  const disciplinesToShow =
    countDisciplines <= 0
      ? disciplines
      : [
          ...disciplines.slice(0, 1),
          {
            id: 'add',
            name: `Y ${countDisciplines} más`,
            title: disciplines
              .map((e) => e.name)
              .slice(1, disciplines.length)
              .join(', ')
          }
        ]

  const eventDateFormat = getDateEventFormat(fromLocal, toLocal)
  const hasCoords = latitude !== 0 && longitude !== 0
  const locationEvent = [countryName, areaLevel1Name, address]
    .filter(Boolean)
    .join(', ')
    .concat(`. ${!hasCoords ? ' La ubicación exacta se informará próximamente' : ''}`)

  const hasProfileImage = Array.isArray(profileImages) && profileImages.length > 0

  const organizationsToShow = organizations
    .filter((e) => !!e.acceptedAt)
    .sort((x, y) => x.acceptedAt - y.acceptedAt)
    .map(({ organizationHandle, organizationName }) => organizationHandle || organizationName)
    .join(' & ')

  const handleActionEvent = (action) => {
    if (!user) {
      dispatch(verifyUserNotLogged({ state: true }))
    } else {
      dispatch(action(event))
    }
  }

  const handleClick = (e) => {
    e.preventDefault()

    if (afterEvent || hasMoreInformation) {
      history.push(`${ROUTES.EVENTS.PROFILE}/${slugUrl}`)
    } else {
      setIsModalOpen(true)
    }
  }

  const options = disciplines.map((x) => ({
    ...x,
    imgUrl: DISCIPLINE_IMAGES[x.imageName],
    className: clsx(x.imageName && classes[x.imageName])
  }))

  return (
    <div className={classes.card} id='event-card'>
      <Link className={classes.container} onClick={handleClick} to='#'>
        <div
          className={clsx(
            classes.headerContainer,
            hasResults && classes.headerContainerResult,
            isFinished && classes.headerContainerResult,
            isSuspended && 'suspended'
          )}>
          <Typography className={classes.date}>{eventDateFormat}</Typography>
          <div className={classes.sportContainer}>
            {disciplinesToShow.map((d) => (
              <div className={classes.sport} key={d.id} title={d.title}>
                {d.name}
              </div>
            ))}
          </div>
        </div>
        <div className={classes.featureImageContainer} id='feature-image-container'>
          {hasProfileImage ? (
            <div
              className={classes.featureImage}
              id='feature-image'
              style={{ backgroundImage: `url(${profileImages[0]})` }}
            />
          ) : (
            options
              .filter((x) => !!x.imageName)
              .map((x) => (
                <div
                  key={x.id}
                  className={clsx(
                    classes.featureImage,
                    x.className,
                    `size-${options.filter((x) => !!x.imageName).length}`
                  )}
                  style={{ backgroundImage: `url(${x.imgUrl})` }}
                />
              ))
          )}
        </div>
      </Link>

      <div className={clsx(classes.actionButtonContainer, isSuspended && 'suspended')}>
        {!disabledAction && (
          <div className={classes.actionSocialBarButton}>
            <IconButton
              className={classes.actionButton}
              disabled={pendingReact}
              onClick={() => handleActionEvent(reactEvent)}>
              {event?.loggedUserMetadata && event.loggedUserMetadata.like ? (
                <FavoriteIcon />
              ) : (
                <FavoriteBorderIcon />
              )}
              {!!likesCount && <em>{likesCount}</em>}
            </IconButton>
            <ShareUrlMenuDialog
              id={id}
              updatedAt={updatedAt ?? createdAt}
              type='event'
              isHiddenLabel
              isOutlineIcon
              buttonClassName={classes.shareButton}
              copyMessage='El link del evento fue copiado al portapapeles'
              suspended={isSuspended}
              slugUrl={slugUrl}
              title={name}
            />
          </div>
        )}
      </div>
      <Link className={classes.container} onClick={handleClick} to='#'>
        <div className={classes.nameEventContainer}>
          <Typography
            color='primary'
            variant='h5'
            className={clsx(classes.nameEvent, isSuspended && 'suspended')}
            title={name}>
            {name}
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <div className={classes.organizationContainer}>
            <Typography className={classes.organization} title={`Organiza ${organizationsToShow}`}>
              Organiza <span>{organizationsToShow}</span>
            </Typography>
          </div>
          {!isSuspended ? (
            <div className={classes.locationContainer}>
              {hasCoords && (
                <LocationOnOutlinedIcon color='primary' style={{ width: 25, height: 25 }} />
              )}
              <Typography component='p' title={locationEvent}>
                {locationEvent}
              </Typography>
            </div>
          ) : (
            <div className={classes.messageContainer}>
              <Typography color='primary' variant='h5'>
                EVENTO SUSPENDIDO
              </Typography>
            </div>
          )}
        </div>
      </Link>
      <div className={classes.endContainer}>
        {hasResults ? (
          <Link to={`${ROUTES.EVENTS.PROFILE}/${slugUrl}`} className={classes.link}>
            Ver resultados
          </Link>
        ) : (
          <>
            <Link onClick={handleClick} className={classes.link} to='#'>
              Ver más
            </Link>
            {isActiveInscription &&
              (!externalInscriptionUrl ? (
                <Link to={`/events/${slugUrl}/inscription`} className={classes.linkInscription}>
                  {!loggedUserMetadata?.hasActiveInscription ? 'Inscribirme' : 'Inscripción'}
                </Link>
              ) : (
                <a
                  href={externalInscriptionUrl}
                  className={classes.linkInscription}
                  target='_blank'
                  rel='noopener noreferrer'>
                  {!loggedUserMetadata?.hasActiveInscription ? 'Inscribirme' : 'Inscripción'}
                </a>
              ))}
            <EventCardPreview
              open={isModalOpen}
              like={!!event.loggedUserMetadata && event.loggedUserMetadata.like}
              disciplinesToShow={disciplinesToShow}
              imageUrl={hasProfileImage ? profileImages[0] : null}
              eventDisciplines={options}
              eventDateFormat={eventDateFormat}
              organizationsToShow={organizationsToShow}
              hasCoords={hasCoords}
              locationEvent={locationEvent}
              setIsModalOpen={setIsModalOpen}
              event={event}
            />
          </>
        )}

        {disabledAction && (
          <ShareUrlMenuDialog
            id={id}
            updatedAt={updatedAt ?? createdAt}
            type='event'
            isHiddenLabel
            isOutlineIcon
            buttonClassName={clsx(classes.shareButton, 'alt')}
            copyMessage='El link del evento fue copiado al portapapeles'
            suspended={isSuspended}
            slugUrl={slugUrl}
            title={name}
          />
        )}
      </div>
    </div>
  )
}

export default EventCard
