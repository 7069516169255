import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Typography, useMediaQuery } from '@material-ui/core'
import {
  loadPasteventsByMyOrganization,
  resetPastEventsByMyOrganization
} from 'state/modules/userAccounts'

import { EventContainer } from '../EventContainer'
import { SectionBlock } from '../SectionBlock'

import { useStyles } from './PastEventsByMyOrganizationSection.style'

const STEP_NUMBER = 1

const PastEventsByMyOrganizationSection = ({ myOrganization }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const {
    pastEventsByMyOrganization,
    pastEventsByMyOrganizationPageNumber,
    pastEventsByMyOrganizationLastPage
  } = useSelector((state) => state.userAccounts)

  useEffect(() => {
    if (myOrganization) dispatch(loadPasteventsByMyOrganization(myOrganization.id))

    return () => dispatch(resetPastEventsByMyOrganization())
  }, [myOrganization])

  const minForRepeat = isDesktop ? 4 : 1

  return (
    !!pastEventsByMyOrganization.length && (
      <SectionBlock
        sectionClassName={classes.section}
        title={!isDesktop && 'Eventos pasados de Mi organización'}>
        <div className={classes.mainContainer}>
          {isDesktop && (
            <Typography className={classes.title} color='primary' variant='h6'>
              Eventos pasados de Mi organización
            </Typography>
          )}
          <EventContainer
            events={pastEventsByMyOrganization}
            autoplay={true}
            isFinished={true}
            nextPage={pastEventsByMyOrganizationPageNumber + STEP_NUMBER}
            lastPage={pastEventsByMyOrganizationLastPage}
            infinite={pastEventsByMyOrganization.length > minForRepeat}
            fetchNewPage={(page) =>
              dispatch(loadPasteventsByMyOrganization(myOrganization.id, page))
            }
          />
        </div>
      </SectionBlock>
    )
  )
}

export default PastEventsByMyOrganizationSection
