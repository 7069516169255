import React from 'react'
import { Link } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import { SearchWidget } from 'pages/HomePage/components'
import { ROUTES } from 'routes'
import { MainLayout, NavBar, PageContainer } from 'shared'

import { QuestionBlock } from './components'
import { useStyles } from './FrequentQuestionsPage.style'

const FrequentQuestionsPage = () => {
  const classes = useStyles()

  return (
    <MainLayout>
      <NavBar widget={(props) => <SearchWidget mini {...props} />} showWidget />

      <PageContainer customContainerClassName={classes.container}>
        <div className={classes.mainContainer}>
          <Typography variant='h2' color='primary' className={classes.title}>
            Preguntas Frecuentes
          </Typography>
          <div className={classes.content}>
            <QuestionBlock
              question='¿Es necesario registrarme para ver los eventos y calendario de Sportmetric?'
              response='No, no es necesario estar registrado.'
            />

            <QuestionBlock
              question='¿Tengo que tener cuenta para inscribirme en un evento?'
              response='Si el organizador habilitó la inscripción sin cuenta para el evento, no necesitás estar registrado. Caso contrario si será necesario tener cuenta.'
            />

            <QuestionBlock
              question='¿Qué puedo hacer en Sportmetric si no tengo cuenta?'
              response={
                <>
                  Sin cuenta accedés a las siguientes funcionalidades:
                  <ul className={classes.list}>
                    <li>Seguir el calendario deportivo.</li>
                    <li>
                      Realizar búsqueda de eventos por ubicación, disciplina, o por organización.
                    </li>
                    <li>
                      Inscribirte en los eventos que el organizador permita que se realicen sin
                      cuenta.
                    </li>
                    <li>Ver los resultados de los eventos deportivos.</li>
                    <li>
                      Recibir notificaciones sobre cambios en el evento al cual te inscribiste o
                      monitorearlo a través del perfil del evento.
                    </li>
                  </ul>
                </>
              }
            />

            <QuestionBlock
              question='¿Cuáles son los beneficios de estar registrado?'
              response={
                <>
                  <ul className={classes.list}>
                    <li>Al registrarte podés definir tu rol: deportista, organizador, o ambos.</li>
                    <li>
                      Como Organizador podés crear tu organización o colaborar en la administración
                      de una organización ya existente. Entre otras cosas. Si sos organizador te
                      recomendamos visitar&nbsp;
                      <Link
                        to={ROUTES.LANDING.ORGANIZATION}
                        target='_blank'
                        rel='noopener noreferrer'
                        className={classes.link}>
                        Sportmetric
                      </Link>
                      , para conocer más al respecto.
                    </li>
                    <li>
                      Si te registrás como deportista las inscripciones a los eventos son más ágiles
                      y sencillas.
                    </li>
                    <li>
                      Tenés registro de tu historial deportivo y las posiciones alcanzadas en cada
                      competencia cuando la organización sube los resultados del evento (siempre que
                      los eventos sean publicados en Sportmetric).
                    </li>
                    <li className={classes.hiddenQuestion}>
                      Próximamente recibirás notificaciones de las organizaciones que sigues cuando
                      publiquen nuevos eventos que pueden ser de tu interés.
                    </li>
                  </ul>
                </>
              }
            />

            <QuestionBlock
              question='¿Tiene costo registrarme en sportmetric?'
              response='No, crear tu usuario en sportmetric es Gratis.'
            />

            <QuestionBlock
              question='¿Puedo tener más de una cuenta?'
              response='No, la cuenta es única y personal.'
            />

            <QuestionBlock
              className={classes.hiddenQuestion}
              question='Me registré con un email que ya no uso ¿Puedo cambiarlo?'
              response='Si, podés cambiarlo desde tu perfil accediendo a Configuración y seguridad de la cuenta.'
            />

            <QuestionBlock
              question='No recuerdo mi contraseña ¿Qué debo hacer para poder ingresar?'
              response={
                <>
                  Desde el registro hacé click en olvidé mi contraseña y te enviaremos un mail para
                  poder cambiarla.
                  <br />
                  Si tenés inconveniente para acceder a tu cuenta podés escribirnos a&nbsp;
                  <a
                    href='mailto:soporte@sportmetric.net'
                    target='_blank'
                    className={classes.link}
                    rel='noopener noreferrer'>
                    soporte@sportmetric.net
                  </a>
                  &nbsp;indicando el problema para que podamos asistirte.
                </>
              }
            />

            <QuestionBlock
              question='Me inscribí para participar de un evento ¿Cómo subo el comprobante de pago?'
              response={
                <>
                  En el perfil del evento haciendo click en Estado de mi inscripción se abrirá una
                  ventana emergente en la cual debes seleccionar tu país (en caso de no ser
                  Argentino) y escribir tu DNI tal como lo escribiste al completar el formulario.
                  <br />
                  Esto te lleva a una pantalla en la cual verás los datos del evento y el botón
                  subir comprobante para que puedas presentarlo. En caso que el organizador haya
                  habilitado varios métodos de pago podrás también, desde esta pantalla, acceder a
                  cambiar la modalidad de pago elegida.
                </>
              }
            />
          </div>
        </div>
      </PageContainer>
    </MainLayout>
  )
}

export default FrequentQuestionsPage
