import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { Button, Drawer, IconButton, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon
} from '@material-ui/icons'
import noBackgroundImage from 'assets/img/no-image.jpg'
import noProfileImage from 'assets/img/user-mock.png'
import clsx from 'clsx'
import moment from 'moment'
import { SearchWidget } from 'pages/HomePage/components'
import { UserAccountsProxy } from 'services'
import {
  CustomizedTooltip,
  EditableImage,
  EditableMultipleSelection,
  EditableText,
  Footer,
  MainLayout,
  NavBar,
  PageContainer,
  ShareUrlMenuDialog
} from 'shared'
import { verifyUserNotLogged } from 'state/modules/app'
import {
  deleteImage as deleteImageUserAccount,
  follow,
  load,
  loadFollowedOrganizations,
  loadFollowedUserAccounts,
  loadOrganizationsAdminAll,
  loadReset,
  setImage,
  updateUserAccount
} from 'state/modules/userAccounts'
import { USER_ROLE } from 'utils/constants'
import { removeDiacritics, searchDisciplines } from 'utils/functions'
import { showSnackbarError } from 'utils/snackbar'

import {
  FormEditDialog,
  ManageOrganizationsSection,
  PastEventsByMyOrganizationSection,
  PlaceHolderLoader,
  SectionBlock,
  SectionCalendar,
  SectionFollowed,
  SectionInformation,
  SectionMyOrganization,
  SectionProfile,
  SectionResults,
  UpcomingEventManageByMeSection,
  UpcomingEventsByMyOrganizationSection
} from './components'
import { useStyle } from './ProfilePage.style'

const ProfilePage = () => {
  const classes = useStyle()
  const dispatch = useDispatch()
  const theme = useTheme()
  const { userAccountId } = useParams()
  const mediumDevice = useMediaQuery(theme.breakpoints.up('md'))

  const {
    userAccount,
    pending,
    followedOrganizations,
    followedUserAccounts,
    followPending,
    organizationsAdminAll,
    error: asyncError
  } = useSelector((state) => state.userAccounts)
  const { user } = useSelector((state) => state.auth)
  const { activeRole } = useSelector((state) => state.app)

  const [openFormEditDialog, setOpenFormEditDialog] = useState(false)
  const [openDrawer, setOpenDrawer] = useState(true)

  const activeLoading = (!pending && !userAccount) || pending
  const LocationMessageError = {}
  const userMessageError = {}
  const myOrganization = [...organizationsAdminAll]
    .sort((a, b) => moment(a.organization.createdAt).diff(moment(b.organization.createdAt)))
    .find((x) => x.organization.createdById === userAccountId)

  useEffect(() => {
    dispatch(load(userAccountId))
    dispatch(loadFollowedOrganizations(userAccountId))
    dispatch(loadFollowedUserAccounts(userAccountId))

    const myProfile = !!user && userAccountId === user.id
    if (myProfile) dispatch(loadOrganizationsAdminAll())

    return () => {
      dispatch(loadReset())
    }
  }, [dispatch, userAccountId, user])

  useEffect(() => {
    showMessage(asyncError)
  }, [asyncError])

  const handleSave = ({ field, value }) => {
    const dataUpdate = field ? { [field]: value } : { ...value }
    const userAccountUpdate = { ...userAccount, ...dataUpdate, password: null }
    dispatch(updateUserAccount(userAccountUpdate))
  }

  const showMessage = (error) => {
    if (error) showSnackbarError(error)
  }

  const validationUserName = async (email) => {
    if (email) {
      const proxy = new UserAccountsProxy()
      try {
        const response = await proxy.existsUsernameEmail({
          UsernameOrEmail: email
        })
        return response
      } catch (e) {
        return false
      }
    } else {
      return false
    }
  }

  const hasError = async (data, type, field, subField) => {
    const message = 'Este campo '

    const alphanumericPattern = '[a-zA-Z0-9]+$'
    const pattern = /^[a-z ,.]+$/i

    const ARGENTINA_ID = '01fa2de7-519f-4089-a33c-d48483ea0f20'
    const OTHER_OPTION = { label: 'Otra', value: 'other' }

    let responseMessage = ''

    switch (type) {
      case 'number': {
        const numTest = +data

        if (Number.isNaN(numTest)) responseMessage = 'Ingrese un número válido'

        if (!responseMessage && !!data.length) {
          if (!Number.isInteger(numTest)) {
            responseMessage = `${message} no puede contener decimales`
          } else if (numTest < 0) {
            responseMessage = `${message} no puede contener un número negativo`
          } else {
            switch (field) {
              case 'height':
                if (numTest < 50 || numTest > 250) {
                  responseMessage = `${message} puede variar entre 50cm y 250cm`
                }
                break
              case 'weight':
                if (numTest < 10 || numTest > 350) {
                  responseMessage = `${message} puede variar entre 10Kg y 350Kg`
                }
                break
              default:
                break
            }
          }
        }
        break
      }

      case 'date':
        if (!responseMessage && field === 'birthdate') {
          if (!data) {
            responseMessage = 'Debe ingresar su fecha de nacimiento'
          } else if (!moment(data[field]).isValid()) {
            responseMessage = 'La fecha ingresada no es válida'
          } else if (
            !!data &&
            data[field] &&
            (moment().diff(data[field], 'years') < 12 || moment().diff(data[field], 'years') > 120)
          ) {
            responseMessage = 'La edad permitida es entre 12 y 120 años'
          }
        }
        break

      case 'text':
        if (field === 'firstName') {
          const firstName = data[field]?.trim() && removeDiacritics(data[field].trim())

          if (!firstName) {
            userMessageError[field] = 'Ingresa tu nombre'
          } else if (!firstName.match(pattern)) {
            userMessageError[field] = 'El nombre no debe tener caracteres especiales ni números'
          } else if (firstName.length > 256) {
            userMessageError[field] = 'El nombre tiene un máximo de 256 caracteres'
          } else {
            userMessageError[field] = ''
          }
        }

        if (subField === 'lastName') {
          const lastName = data[subField]?.trim() && removeDiacritics(data[subField].trim())

          if (!lastName) {
            userMessageError[subField] = 'Ingresa tu apellido'
          } else if (!lastName.match(pattern)) {
            userMessageError[subField] =
              'El apellido no debe tener caracteres especiales ni números'
          } else if (lastName.length > 256) {
            userMessageError[subField] = 'El apellido tiene un máximo de 256 caracteres'
          } else {
            userMessageError[subField] = ''
          }
        }

        if (!responseMessage && field === 'username') {
          if (!data?.trim()) {
            responseMessage = 'Ingresa tu nombre de usuario'
          } else if (data.search(alphanumericPattern)) {
            responseMessage = 'El nombre de usuario no debe tener caracteres especiales ni espacios'
          } else if (data.length < 3) {
            responseMessage = 'El nombre de usuario debe tener como mínimo 3 caracteres'
          } else if (data.length > 30) {
            responseMessage = 'El nombre de usuario tiene un máximo 30 caracteres'
          } else if (data !== userAccount?.username) {
            const response = await validationUserName(data)
            responseMessage = response && 'El nombre de usuario ya está en uso'
          }
        }

        if (!responseMessage && field === 'idNumber') {
          data = (data ?? '').trim()
          responseMessage = !data && `${message} es requerido. No puede estar vacío`

          if (!responseMessage && data.length < 6) {
            responseMessage = `${message} debe poseer como mínimo 6 caracteres`
          } else if (!responseMessage && data.length > 15)
            responseMessage = `${message} debe poseer como máximo 15 caracteres`
          else if (!responseMessage && data.search(alphanumericPattern)) {
            responseMessage = `${message} no admite el ingreso de caracteres especiales ni espacios`
          }
        }
        break

      case 'list':
        if (!responseMessage && Array.isArray(field) && field.some((x) => x === 'areaLevel1Id')) {
          if (!data.areaLevel1Id.value && data.countryId.value === ARGENTINA_ID) {
            LocationMessageError.areaLevel1Id = 'El campo provincia es obligatorio'
          } else {
            LocationMessageError.areaLevel1Id = ''
          }
        }

        if (!responseMessage && Array.isArray(field) && field.some((x) => x === 'areaLevel2Id')) {
          if (
            data.countryId.value === ARGENTINA_ID &&
            (!data.areaLevel2Id.value ||
              (data.areaLevel2Id.value === OTHER_OPTION.value &&
                !data.areaLevel2Id.otherOption?.trim()))
          ) {
            LocationMessageError.areaLevel2Id = 'El campo ciudad es obligatorio'
          } else {
            LocationMessageError.areaLevel2Id = ''
          }
        }

        break
      default:
        break
    }

    return responseMessage
  }

  const handleSaveImage = ({ value, type }) => {
    dispatch(setImage(userAccount.id, value, type))
  }

  const handleDeleteImage = ({ type }) => {
    dispatch(deleteImageUserAccount(userAccount, type))
  }

  const handleSaveFullName = ({ value: { firstName, lastName }, ...restProps }) =>
    handleSave({
      ...restProps,
      value: {
        firstName: firstName.trim(),
        lastName: lastName.trim()
      }
    })

  const handleFollow = () => {
    if (!user) {
      dispatch(verifyUserNotLogged({ state: true }))
    } else {
      dispatch(follow(userAccount))
    }
  }

  const renderLoader = () => <PlaceHolderLoader />

  const renderRunner = () => {
    const { loggedUserMetadata, followersCount, firstName, disciplines, updatedAt, createdAt } =
      userAccount

    const profileImageUrl =
      Array.isArray(userAccount.profileImages) && userAccount.profileImages.length > 0
        ? userAccount.profileImages[0]
        : noProfileImage
    const backgroundImageUrl =
      Array.isArray(userAccount.backgroundImages) && userAccount.backgroundImages.length > 0
        ? userAccount.backgroundImages[0]
        : noBackgroundImage
    const { canEdit, isFollowing } = loggedUserMetadata
    const myProfile = user?.id === userAccount?.id || false
    const sectionInformationProps = {
      userAccount,
      handleSave,
      hasError,
      canEdit,
      errors: LocationMessageError
    }
    const calendarProps = { userAccountId, firstName }

    return (
      <div className={classes.container}>
        {(!canEdit || !activeRole || activeRole === USER_ROLE.ATHLETE) && (
          <>
            <div className={classes.mainContainer}>
              <div className={classes.headerContainer}>
                <EditableImage
                  entity={userAccount}
                  defaultImage={noBackgroundImage}
                  field='backgroundImages'
                  onSave={handleSaveImage}
                  canEdit={canEdit}
                  inputClassName={classes.featureImage}
                  onDelete={handleDeleteImage}
                  type='background'>
                  <div
                    className={classes.featureImage}
                    style={{ backgroundImage: `url(${backgroundImageUrl})` }}
                  />
                </EditableImage>
                <div className={classes.userAccountInfoContainer}>
                  <EditableImage
                    entity={userAccount}
                    field='profileImages'
                    onSave={handleSaveImage}
                    canEdit={canEdit}
                    defaultImage={noProfileImage}
                    inputClassName={classes.profileImage}
                    size={{ width: 30, height: 30, fontsize: 13 }}
                    type='profile'
                    onDelete={handleDeleteImage}>
                    <div
                      className={classes.profileImage}
                      style={{ backgroundImage: `url(${profileImageUrl})` }}
                    />
                  </EditableImage>

                  <div className={classes.titleContainer}>
                    <EditableText
                      entity={userAccount}
                      field='firstName'
                      subField='lastName'
                      placeHolder={{
                        firstName: 'Ingrese su nombre',
                        lastName: 'Ingrese su apellido'
                      }}
                      onSave={handleSaveFullName}
                      validation={hasError}
                      inputClassName={classes.inputEdit}
                      type='text'
                      errors={userMessageError}>
                      <div className={classes.fullNameContainer}>
                        <Typography
                          variant='h2'
                          className={classes.title}
                          color='primary'
                          title={userAccount.firstName}>
                          {userAccount.firstName}
                        </Typography>
                        &nbsp;
                        <Typography
                          variant='h2'
                          className={classes.title}
                          color='primary'
                          title={userAccount.lastName}>
                          {userAccount.lastName}
                        </Typography>
                      </div>
                    </EditableText>
                    <div className={classes.containerUsername}>
                      <div style={{ flexGrow: 1 }}>
                        <EditableText
                          entity={userAccount}
                          field='username'
                          onSave={handleSave}
                          validation={hasError}
                          inputClassName={classes.inputEdit}
                          placeHolder='Ingrese su nombre de usuario'
                          type='text'>
                          <Typography
                            variant='h3'
                            className={classes.username}
                            color='primary'
                            title={userAccount.username}>
                            {`@${userAccount.username || userAccount.id}`}
                          </Typography>
                        </EditableText>
                      </div>
                    </div>
                  </div>
                  <div className={classes.informationContainer}>
                    <EditableMultipleSelection
                      entity={userAccount}
                      field='disciplines'
                      onSave={handleSave}
                      selectClassName={classes.asyncSelect}
                      subField='name'
                      placeholder='Seleccione sus disciplinas'
                      isMultiline={true}
                      loadOptions={searchDisciplines}>
                      <div className={classes.sportsContainer}>
                        {disciplines.length ? (
                          disciplines.map((d) => (
                            <div className={classes.sport} key={d.id}>
                              {d.name}
                            </div>
                          ))
                        ) : (
                          <Typography color='primary' variant='h6'>
                            SIN DISCIPLINA
                          </Typography>
                        )}
                      </div>
                    </EditableMultipleSelection>
                    {canEdit && (
                      <>
                        <Button
                          color='primary'
                          variant='outlined'
                          className={classes.buttonOpenEdit}
                          onClick={() => setOpenFormEditDialog(true)}>
                          Editar perfil
                        </Button>
                        <FormEditDialog
                          open={openFormEditDialog}
                          onClose={() => setOpenFormEditDialog(false)}
                          values={userAccount}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.containerActionBar}>
              <div className={classes.mainContainer}>
                <div className={classes.actionBar}>
                  <div className={classes.actionBarButtonContainer}>
                    <Typography color='primary'>{`${followersCount} Seguidores`}</Typography>
                    <div>
                      {!myProfile &&
                        (!isFollowing ? (
                          <Button
                            color='primary'
                            disabled={followPending}
                            variant='outlined'
                            className={classes.buttonFollow}
                            onClick={() => {
                              handleFollow()
                            }}>
                            Seguir
                          </Button>
                        ) : (
                          <Button
                            color='primary'
                            className={classes.buttonFollowing}
                            disabled={followPending}
                            variant='contained'
                            onClick={() => {
                              handleFollow()
                            }}>
                            Siguiendo
                          </Button>
                        ))}

                      <ShareUrlMenuDialog
                        id={userAccountId}
                        type='userprofile'
                        updatedAt={updatedAt || createdAt}
                        copyMessage='El link de tu perfil fue copiado al portapapeles'
                        slugUrl={userAccount.username || userAccount.id}
                        title={userAccount.title}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <div className={classes.containerBody}>
          <div className={classes.mainContainer}>
            <div className={clsx(classes.rowContainer, !openDrawer && 'hidden')}>
              {!canEdit || !activeRole || activeRole === USER_ROLE.ATHLETE ? (
                <>
                  <div className={classes.leftColumn}>
                    {mediumDevice ? (
                      <>
                        <SectionResults userAccount={userAccount} />
                        <SectionCalendar {...calendarProps} />
                      </>
                    ) : (
                      <>
                        <SectionCalendar {...calendarProps} />
                        <SectionFollowed
                          myProfile={myProfile}
                          canEdit={canEdit}
                          followedOrganizations={followedOrganizations}
                          followedUserAccounts={followedUserAccounts}
                        />
                      </>
                    )}
                  </div>
                  <div className={classes.rightColumn}>
                    {mediumDevice ? (
                      <>
                        <SectionInformation {...sectionInformationProps} />
                        <SectionFollowed
                          myProfile={myProfile}
                          canEdit={canEdit}
                          followedOrganizations={followedOrganizations}
                          followedUserAccounts={followedUserAccounts}
                        />
                      </>
                    ) : (
                      <>
                        <SectionInformation {...sectionInformationProps} />
                        <SectionResults userAccount={userAccount} />
                      </>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className={clsx(classes.leftColumn, openDrawer && 'hidden')}>
                    {mediumDevice ? (
                      <>
                        <SectionMyOrganization
                          myOrganization={myOrganization}
                          expanded={!openDrawer}
                        />

                        {myOrganization && (
                          <UpcomingEventsByMyOrganizationSection
                            expanded={!openDrawer}
                            myOrganization={myOrganization.organization}
                          />
                        )}

                        {myOrganization && (
                          <PastEventsByMyOrganizationSection
                            myOrganization={myOrganization.organization}
                          />
                        )}

                        <ManageOrganizationsSection />

                        <UpcomingEventManageByMeSection />
                      </>
                    ) : (
                      <>
                        <SectionMyOrganization myOrganization={myOrganization} />

                        {myOrganization && (
                          <UpcomingEventsByMyOrganizationSection
                            myOrganization={myOrganization.organization}
                          />
                        )}

                        {myOrganization && (
                          <PastEventsByMyOrganizationSection
                            myOrganization={myOrganization.organization}
                          />
                        )}

                        <ManageOrganizationsSection />

                        <UpcomingEventManageByMeSection />
                      </>
                    )}
                  </div>

                  <SectionBlock
                    sectionClassName={clsx(
                      classes.rightColumn,
                      activeRole === USER_ROLE.ORGANIZER && 'alt',
                      !openDrawer && 'hidden'
                    )}
                    title={!mediumDevice && 'Perfil'}>
                    <CustomizedTooltip
                      title={openDrawer ? 'Contraer' : 'Expandir'}
                      position='bottom'
                      className={classes.tooltip}
                      arrowClassName={classes.arrow}
                      popperClassName={classes.tooltipPopper}>
                      {({ handleTooltipClose, handleTootipOpen }) => (
                        <IconButton
                          onClick={() => {
                            handleTooltipClose()
                            setOpenDrawer(!openDrawer)
                          }}
                          color='primary'
                          className={classes.button}
                          onMouseOut={handleTooltipClose}
                          onTouchEnd={handleTooltipClose}
                          onTouchMove={handleTootipOpen}
                          onMouseOver={handleTootipOpen}
                          size='small'>
                          {!openDrawer ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>
                      )}
                    </CustomizedTooltip>

                    <Drawer
                      variant='persistent'
                      anchor='right'
                      open={openDrawer}
                      className={classes.drawerSection}
                      onClose={() => setOpenDrawer(false)}>
                      <SectionProfile
                        profileImageUrl={profileImageUrl}
                        userAccount={userAccount}
                        canEdit={canEdit}
                        noProfileImage={noProfileImage}
                        handleDeleteImage={handleDeleteImage}
                        handleSaveImage={handleSaveImage}
                      />

                      <SectionInformation {...sectionInformationProps} />
                      <SectionFollowed
                        myProfile={myProfile}
                        canEdit={canEdit}
                        followedOrganizations={followedOrganizations}
                        followedUserAccounts={followedUserAccounts}
                      />
                    </Drawer>
                  </SectionBlock>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <MainLayout>
      <NavBar widget={(props) => <SearchWidget mini {...props} />} showWidget />
      <PageContainer altMode>{!activeLoading ? renderRunner() : renderLoader()}</PageContainer>
      <Footer />
    </MainLayout>
  )
}
export default ProfilePage
