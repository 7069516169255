import React from 'react'
import { numericFormatter } from 'react-number-format'
import { useSelector } from 'react-redux'
import { Typography } from '@material-ui/core'
import clsx from 'clsx'
import moment from 'moment'
import { PAYMENT_TYPES, PRICE_FORMAT, STRING_EMPTY } from 'utils/constants'

import { SectionBlock } from '../SectionBlock'

import { useStyles } from './InscriptionTicketsSection.style'

const FREE_INSCRIPTION_PRICE = 0

const PAYMENT_TYPES_LABELS = {
  [PAYMENT_TYPES.MercadoPago]: 'MP',
  [PAYMENT_TYPES.BankAccount]: 'Transferencia',
  [PAYMENT_TYPES.PaymentUrl]: 'Link de pago',
  [PAYMENT_TYPES.PaymentLocation]: 'Pago en efectivo'
}

const InscriptionTicketsSection = ({ canEdit }) => {
  const classes = useStyles()
  const { event } = useSelector((state) => state.events)

  const getTicketAvailible = () =>
    event.activeInscriptionForm?.ticketTypes.filter(
      (x) => x.isActive && moment().isBetween(moment(x.saleFrom), moment(x.saleTo))
    )

  const getPriceFormat = (price) =>
    numericFormatter(Number(Number(price).toFixed(2)).toString(), {
      ...PRICE_FORMAT,
      thousandSeparator: STRING_EMPTY,
      fixedDecimalScale: !Number.isInteger(price)
    })

  const getTicketInfo = (ticket) => {
    const sinQuota =
      (!!event.activeInscriptionForm.quota &&
        event.activeInscriptionForm.quota <= event.activeInscriptionForm.inscriptionsCount) ||
      (!!ticket.quota && ticket.quota <= ticket.inscriptionsCount)

    const saleToLocal = moment(ticket.saleTo).format('DD/MM/YYYY')

    if (event.isFree)
      return (
        <>
          Hasta {saleToLocal}&nbsp;
          {sinQuota && <span className={classes.quota}>SIN CUPO</span>}
        </>
      )

    if (ticket.price === FREE_INSCRIPTION_PRICE)
      return (
        <>
          <strong>Gratis</strong>&nbsp;
          {sinQuota && <span className={classes.quota}>SIN CUPO</span>}
        </>
      )

    if (sinQuota)
      return (
        <>
          Hasta {saleToLocal}&nbsp;
          <span className={classes.quota}>SIN CUPO</span>
        </>
      )

    const paymentMethods = [
      {
        method: PAYMENT_TYPES.MercadoPago,
        isActive: ticket.mercadoPagoEnabled
      },
      {
        method: PAYMENT_TYPES.PaymentLocation,
        isActive: ticket.paymentLocationsEnabled
      },
      {
        method: PAYMENT_TYPES.PaymentUrl,
        isActive: !!ticket.paymentUrl
      },
      { method: PAYMENT_TYPES.BankAccount, isActive: ticket.bankAccountsEnabled }
    ]
      .filter((x) => x.isActive)
      .map((x) => PAYMENT_TYPES_LABELS[x.method])
      .join(' - ')

    const hasServiceFee = ticket.mercadoPagoEnabled

    return (
      <>
        Hasta {saleToLocal} -&nbsp;
        <strong>
          {getPriceFormat(ticket.price)} {hasServiceFee && '+ cargos de servicio'} -&nbsp;
          {paymentMethods}
        </strong>
      </>
    )
  }

  const hasActiveInscriptionForm =
    !!event.activeInscriptionFormId &&
    !!event.activeInscriptionForm &&
    !event.activeInscriptionForm.isDraft

  return (
    <SectionBlock
      title='Tickets de inscripción'
      titleClassName={clsx(!hasActiveInscriptionForm && classes.disabledTitle)}>
      <div className={classes.ticketContainer}>
        {hasActiveInscriptionForm ? (
          <div className={classes.optionsContainer}>
            {getTicketAvailible().map((x) => (
              <Typography key={x.id} className={classes.ticketInfo}>
                {x.name} - {getTicketInfo(x)}
              </Typography>
            ))}
          </div>
        ) : (
          <Typography className={classes.emptyMessage} variant='h6'>
            Los tickets vigentes se verán aquí, una vez que los habilites en el formulario de
            inscripción de Sportmetric.
          </Typography>
        )}
      </div>

      {canEdit && hasActiveInscriptionForm && (
        <Typography variant='caption' color='primary' className={classes.helperText}>
          *Para editar esta información deberás hacer click en Editar Formulario.
        </Typography>
      )}

      {event.isFree && hasActiveInscriptionForm && (
        <Typography color='primary' variant='h6' className={classes.freeEventLabel}>
          Evento Gratuito
        </Typography>
      )}
    </SectionBlock>
  )
}

export default InscriptionTicketsSection
