import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  section: {
    [theme.breakpoints.up('md')]: {
      width: 'calc(100% + 261px)'
    },

    [theme.breakpoints.up('lg')]: {
      width: 1230
    }
  },
  mainContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  title: {
    fontWeight: 700,
    marginTop: theme.spacing(3),
    fontSize: '1.125rem'
  },
  block: {
    display: 'flex',
    flexDirection: 'column'
  },
  emptyText: {
    fontWeight: 600,
    fontSize: '.875rem'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      '&.expandedSection': {
        width: '100%',
        maxWidth: 500,
        marginRight: theme.spacing(6)
      }
    }
  },
  button: {
    borderRadius: 20,
    width: 280,
    height: 40,
    boxShadow: 'none',
    marginTop: theme.spacing(4),
    marginRight: theme.spacing(3),
    '& span': {
      color: theme.palette.primary.main,
      fontWeight: 700,
      fontSize: '1.125rem'
    },
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(6),
      marginTop: theme.spacing(2)
    }
  }
}))
