import { makeStyles } from '@material-ui/styles'
import logoImage from 'assets/img/icon_sportmetric_simple.png'
import image from 'assets/img/waterMark-Sportmetric.png'

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.secondary.main,
    paddingTop: theme.spacing(4),
    width: '100%',
    minHeight: 50,
    [theme.breakpoints.up('md')]: {
      minHeight: 80
    }
  },
  containerAlt: {
    backgroundColor: 'white'
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      alignItems: 'flex-start',
      margin: '0 auto',
      width: '85%'
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 1344,
      minWidth: 1180
    },
    '&.showFooterContent': {
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row-reverse',
        marginTop: theme.spacing(4)
      }
    }
  },

  logo: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: `url(${logoImage})`,
    width: 59,
    height: 59,
    alignSelf: 'center',
    marginTop: -theme.spacing(7),

    [theme.breakpoints.up('md')]: {
      alignSelf: 'flex-end',
      display: 'block',
      marginRight: -theme.spacing(),
      marginTop: -theme.spacing(7),

      '&.align-start': {
        alignSelf: 'flex-start !important',
        marginTop: -theme.spacing(11.5)
      }
    }
  },
  watermark: {
    [theme.breakpoints.up('md')]: {
      alignSelf: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundImage: `url(${image})`,
      width: 210,
      height: 30,
      marginTop: -3
    }
  },
  footerBlock: {
    display: 'flex',
    paddingLeft: 0,
    flexDirection: 'column',
    alignItems: 'center',
    listStyle: 'none',
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(2),
    '& h6': {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block',
        fontWeight: 500,
        marginTop: -3
      }
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-between'
    }
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(6),
    listStyle: 'none',
    padding: 0,

    '& li': {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2.5),
      [theme.breakpoints.up('md')]: {
        width: 'auto'
      }
    },
    '& a': {
      color: theme.palette.primary.main,
      fontSize: '1rem',
      textDecoration: 'none',
      fontWeight: 500
    },
    [theme.breakpoints.up('md')]: {
      alignItems: 'flex-start',
      gap: 0,
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-between'
    }
  },
  followContainer: {
    display: 'flex',
    flexShrink: 0,
    alignItems: 'flex-end',
    justifyContent: 'center',
    marginRight: -theme.spacing(2),

    '& p': {
      fontWeight: 500,
      marginBottom: theme.spacing()
    },
    [theme.breakpoints.up('md')]: {
      alignSelf: 'flex-end',
      alignItems: 'flex-start',
      justifyContent: 'flex-start'
    }
  },
  facebookIcon: {
    marginTop: -18
  },
  instagramIcon: {
    marginBottom: theme.spacing(0.5),
    marginLeft: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginTop: -theme.spacing(0.5)
    }
  },
  label: {
    fontSize: '1rem',
    fontWeight: 500,
    '& strong': {
      color: theme.palette.primary.main,
      fontWeight: 600
    }
  },
  link: {
    color: theme.palette.primary.main,
    fontWeight: 500,

    '&:hover': {
      fontWeight: 600,
      textDecoration: 'underline'
    }
  },
  altLink: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    display: 'block',
    lineHeight: '16px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: 300,

    '&:hover': {
      fontWeight: 600,
      textDecoration: 'underline'
    },

    [theme.breakpoints.up('md')]: {
      maxWidth: '100%'
    }
  },
  altLabel: {
    fontWeight: '600 !important'
  },
  lastFooterItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: `${theme.spacing(5)}px !important`,
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'right'
    }
  },
  developmentInfo: {
    display: 'flex',
    gap: theme.spacing(2),
    alignSelf: 'center',
    alignItems: 'center'
  },
  developmentText: {
    fontWeight: 600,
    fontSize: '.875rem',
    color: theme.palette.primary.main
  },
  pinardLogo: {
    width: 142,
    height: 58,
    color: theme.palette.primary.main,

    '& path': {
      fill: theme.palette.primary.main
    }
  },
  altItem: {
    gap: `${theme.spacing(6)}px !important`,
    [theme.breakpoints.up('md')]: {
      gap: `${theme.spacing(2.5)}px !important`
    }
  }
}))

export default useStyles
