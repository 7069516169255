import React from 'react'
import { Media } from 'react-breakpoints'
import ContentLoader from 'react-content-loader'

import { useStyles } from './PlaceholderLoader.style'

const PlaceholderLoader = () => {
  const classes = useStyles()
  const bgColor = '#ffffff'
  const fgColor = '#eeeeee'

  return (
    <Media>
      {({ breakpoints, currentBreakpoint }) =>
        breakpoints[currentBreakpoint] > breakpoints.md ? (
          <ContentLoader
            title='Cargando...'
            className={classes.containerHorizontal}
            backgroundColor={bgColor}
            foregroundColor={fgColor}>
            <rect x='150' y='60' className={classes.title} width='100%' height='100%' />
            <rect x='450' y='185' className={classes.subTitle} width='100%' height='100%' />

            <rect x='0' y='255' className={classes.section} width='100%' height='100%' />
            <rect x='0' y='310' className={classes.optionCheck} width='100%' height='100%' />
            <rect x='140' y='310' className={classes.optionCheck} width='100%' height='100%' />

            <rect x='0' y='400' className={classes.fieldName} width='100%' height='100%' />
            <rect x='0' y='420' width='100%' height={38} />

            <rect x='0' y='480' className={classes.fieldName} width='100%' height='100%' />
            <rect x='0' y='500' width='100%' height={38} />

            <rect x='0' y='580' className={classes.fieldName} width='100%' height='100%' />
            <rect x='0' y='610' className={classes.optionCheck} width='100%' height='100%' />
            <rect x='0' y='650' className={classes.optionCheck} width='100%' height='100%' />

            <rect x='0' y='720' className={classes.fieldName} width='100%' height='100%' />
            <rect x='0' y='740' width='48%' height={38} />

            <rect x='600' y='720' className={classes.fieldName} width='100%' height='100%' />
            <rect x='600' y='740' width='48%' height={38} />

            <rect x='0' y='805' className={classes.fieldName} width='100%' height='100%' />
            <rect x='0' y='825' width='48%' height={38} />

            <rect x='600' y='805' className={classes.fieldName} width='100%' height='100%' />
            <rect x='600' y='825' width='48%' height={38} />

            <rect x='0' y='890' className={classes.fieldName} width='100%' height='100%' />
            <rect x='0' y='910' width='48%' height={38} />

            <rect x='600' y='890' className={classes.fieldName} width='100%' height='100%' />
            <rect x='600' y='910' width='48%' height={38} />

            <rect x='0' y='1000' className={classes.section} width='100%' height='100%' />
            <rect x='0' y='1040' width='48%' height={38} />
            <rect x='600' y='1040' width='48%' height={38} />
            <rect x='0' y='1110' width='100%' height={12} />
            <rect x='0' y='1130' width='30%' height={12} />

            <rect x='0' y='1200' className={classes.section} width='100%' height='100%' />
            <rect x='0' y='1230' width='48%' height={38} />

            <rect x='0' y='1300' className={classes.section} width='100%' height='100%' />
            <rect x='0' y='1330' width='48%' height={38} />

            <rect x='0' y='1400' className={classes.section} width='100%' height='100%' />
            <rect x='0' y='1430' width='30%' height={12} />
            <rect x='0' y='1460' width='25%' height={12} />
            <rect x='0' y='1490' width='25%' height={12} />

            <rect x='450' y='1550' width='15%' height={40} />
          </ContentLoader>
        ) : (
          <ContentLoader
            className={classes.containerHorizontal}
            backgroundColor={bgColor}
            foregroundColor={fgColor}>
            <rect x='20' y='10' className={classes.date} width='100%' height='100%' />
            <rect x='40' y='70' className={classes.title} width='100%' height='100%' />
            <rect x='70' y='150' className={classes.subTitle} width='100%' height='100%' />

            <rect x='15' y='220' className={classes.section} width='100%' height='100%' />
            <rect x='15' y='280' className={classes.optionCheck} width='100%' height='100%' />
            <rect x='15' y='330' className={classes.optionCheck} width='100%' height='100%' />

            <rect x='15' y='400' className={classes.fieldName} width='100%' height='100%' />
            <rect x='15' y='420' width='90%' height={38} />

            <rect x='15' y='480' className={classes.fieldName} width='100%' height='100%' />
            <rect x='15' y='500' width='90%' height={38} />

            <rect x='15' y='560' className={classes.fieldName} width='100%' height='100%' />
            <rect x='15' y='590' className={classes.optionCheck} width='100%' height='100%' />
            <rect x='15' y='640' className={classes.optionCheck} width='100%' height='100%' />

            <rect x='15' y='690' className={classes.fieldName} width='100%' height='100%' />
            <rect x='15' y='710' width='90%' height={38} />

            <rect x='15' y='780' className={classes.fieldName} width='100%' height='100%' />
            <rect x='15' y='800' width='90%' height={38} />

            <rect x='15' y='870' className={classes.fieldName} width='100%' height='100%' />
            <rect x='15' y='890' width='90%' height={38} />

            <rect x='15' y='960' className={classes.fieldName} width='100%' height='100%' />
            <rect x='15' y='980' width='90%' height={38} />

            <rect x='15' y='1080' className={classes.section} width='100%' height='100%' />
            <rect x='15' y='1120' width='90%' height={38} />
            <rect x='15' y='1200' width='90%' height={38} />
            <rect x='15' y='1300' width='90%' height={12} />
            <rect x='15' y='1320' width='80%' height={12} />
            <rect x='15' y='1340' width='70%' height={12} />

            <rect x='15' y='1400' className={classes.section} width='100%' height='100%' />
            <rect x='15' y='1430' width='60%' height={12} />
            <rect x='15' y='1460' width='90%' height={38} />

            <rect x='15' y='1530' className={classes.section} width='100%' height='100%' />
            <rect x='15' y='1560' width='60%' height={12} />
            <rect x='15' y='1590' width='90%' height={38} />

            <rect x='15' y='1670' className={classes.section} width='100%' height='100%' />
            <rect x='15' y='1700' width='80%' height={12} />
            <rect x='15' y='1750' width='60%' height={12} />
            <rect x='15' y='1790' width='60%' height={12} />

            <rect x='120' y='1850' width='40%' height={40} />
          </ContentLoader>
        )
      }
    </Media>
  )
}

export default PlaceholderLoader
