import React from 'react'
import Slider from 'react-slick'
import { EventCard } from 'shared'

import { useStyles } from './EventContainer.style'

const NEXT_LOAD_NUMBER = 4

const EventContainer = ({
  events,
  infinite = false,
  slidesToShow = 4,
  nextPage = 1,
  lastPage = false,
  autoplay = false,
  autoplaySpeed = 3000,
  isFinished = false,
  fetchNewPage = () => {}
}) => {
  const classes = useStyles()

  const handleFetch = (current) => {
    if (!lastPage && events.length - NEXT_LOAD_NUMBER === current) fetchNewPage(nextPage)
  }

  const settings = {
    className: classes.slickSlider,
    dots: false,
    arrows: false,
    infinite,
    speed: 500,
    slidesToShow,
    autoplaySpeed,
    autoplay,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ],
    beforeChange: handleFetch
  }
  return (
    <div className={classes.eventContainer}>
      <div className={classes.listContainer}>
        <Slider {...settings}>
          {events.map((e) => (
            <EventCard
              event={e}
              key={e.id}
              reLoadEvents={fetchNewPage}
              disabledAction
              hiddenInscriptionButton
              isFinished={isFinished}
            />
          ))}
        </Slider>
      </div>
    </div>
  )
}

export default EventContainer
