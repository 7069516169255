import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, CircularProgress, IconButton, Typography, useMediaQuery } from '@material-ui/core'
import { Error as ErrorIcon } from '@material-ui/icons'
import clsx from 'clsx'
import { useFormikContext } from 'formik'
import { CustomizedTooltip } from 'shared/CustomizedTooltip'
import { StyledSelect } from 'shared/EditableMultipleSelection/EditableMultipleSelection.style'
import {
  createCategory,
  createDistance,
  deleteDistance,
  downloadEventCategories,
  loadCategories,
  loadDistances
} from 'state/modules/events'
import { GENDERS } from 'utils/constants'

import { AddCategoryDialog } from '../AddCategoryDialog'
import { ConflictCreateDistanceDialog } from '../ConflictCreateDistanceDialog'
import { ConfilctUpdateDistanceDialog } from '../ConflictUpdateDistanceDialog'
import { CopyCategoryDialog } from '../CopyCategoryDialog'
import { CreateCategoryDialog } from '../CreateCategoryDialog'
import { CreateDistanceDialog } from '../CreateDistanceDialog'
import { DeleteCategoryDialog } from '../DeleteCategoryDialog'
import { DeleteDistanceDialog } from '../DeleteDistanceDialog'
import { DuplicateButton } from '../DuplicateButton'
import { EditCategoryDialog } from '../EditCategoryDialog'
import { EditDistanceDialog } from '../EditDistanceDialog'
import { SectionBlock } from '../SectionBlock'
import { SuccessMessage } from '../SuccessMessage'

import { StyledDistanceSwitch, useStyles } from './SectionCategoryAndDistance.style'

const ALL_OPTION = { value: 'All', label: 'Todas' }
const GENDER_ALL_OPTION = { value: 'All', label: 'Todos' }

const GENDER_LIST = [
  { value: GENDERS.MALE, label: 'Masculino', key: 'M' },
  { value: GENDERS.FEMALE, label: 'Femenino', key: 'F' },
  { value: GENDERS.OTHER, label: 'Libre', key: 'L' },
  { value: GENDERS.NONBINARY, label: 'No binario', key: 'X' }
]

const SectionCategoryAndDistance = ({ eventId, categoriesState, distanceState }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const {
    categories,
    categoriesPending,
    event,
    distances,
    distancesPending,
    downloadEventCategoriesPending,
    activeInscriptionFormPending,
    updateDistanceError,
    createDistanceError
  } = useSelector((state) => state.events)

  const { setFieldValue, values, setValues, initialValues } = useFormikContext()

  const [openNotifyUpdateCategoriesDialog, setOpenNotifyUpdateCategoriesDialog] = useState(false)
  const [openConflictCreateDistanceDialog, setOpenConflictCreateDistanceDialog] = useState(false)
  const [openAddCategoryDialog, setOpenAddCategoryDialog] = useState(false)
  const [openCreateDistanceDialog, setOpenCreateDistanceDialog] = useState(false)
  const [openCopyCategoryDialog, setOpenCopyCategoryDialog] = useState(false)
  const [openCreateCategoryDialog, setOpenCreateCategoryDialog] = useState(false)
  const [openDistanceSuccessMessage, setOpenDistanceSuccessMessage] = useState(false)
  const [openCategorySuccessMessage, setOpenCategorySuccessMessage] = useState(false)
  const [openDistanceDuplicateDialog, setOpenDistanceDuplicateDialog] = useState({})
  const [openCategoryDuplicateDialog, setOpenCategoryDuplicateDialog] = useState({})

  const [filterValues, setFilterValues] = useState({
    category: ALL_OPTION,
    gender: GENDER_ALL_OPTION,
    age: ALL_OPTION
  })

  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const disabledFilter = !categories.length
  const disabledCreateDistance =
    !values.allowCategoriesInMultipleDistances &&
    !!categories.length &&
    !categories.filter((c) => !values.items.some((i) => i.eventCategory.id === c.id)).length

  const distancesGroup = values.items
    .filter((x) => categories.some((c) => c.id === x.eventCategoryId))
    .reduce((group, item) => {
      const {
        eventDistanceId,
        eventDistance,
        eventCategory,
        inscriptionsCount = 0,
        hasDistanceChanges
      } = item
      if (!eventDistanceId) return group

      group[eventDistanceId] = group[eventDistanceId] ?? {
        categories: [],
        totalQuotaByDistance: 0,
        hasDistanceChanges,
        distance: eventDistance
      }

      group[eventDistanceId].categories.push({
        ...eventCategory,
        quota: item.quota || '',
        inscriptionsCount
      })

      group[eventDistanceId].totalQuotaByDistance += +item.quota
      group[eventDistanceId].hasDistanceChanges =
        group[eventDistanceId].hasDistanceChanges || hasDistanceChanges

      return group
    }, {})

  const distanceList = distances.map((d) => ({
    distance: d,
    categories: [],
    totalQuotaByDistance: 0,
    ...distancesGroup[d.id]
  }))

  const distanceListResume = distanceList.reduce(
    ({ totalCategoriesCount, totalQuota }, e) => ({
      totalCategoriesCount: totalCategoriesCount + e.categories.length,
      totalQuota: totalQuota + e.totalQuotaByDistance
    }),
    { totalCategoriesCount: 0, totalQuota: 0 }
  )

  useEffect(() => {
    dispatch(loadCategories(eventId))
    dispatch(loadDistances(eventId))
  }, [dispatch, eventId])

  const getGenders = (categoriesItems) => {
    const { category, age } = filterValues
    const categoryOptions =
      category !== ALL_OPTION || age !== ALL_OPTION ? categoriesItems : categories

    return [
      GENDER_ALL_OPTION,
      ...GENDER_LIST.filter((g) => categoryOptions.some((c) => c.gender === g.value))
    ]
  }

  const getAgeList = (categoriesItems) => {
    const { gender, category } = filterValues
    const categoryOptions =
      gender !== GENDER_ALL_OPTION || category !== ALL_OPTION ? categoriesItems : categories

    return [
      ALL_OPTION,
      ...categoryOptions.map((c) => ({ ...c, value: c.id, label: getRangeAgeLabel(c) }))
    ].reduce((acc, el) => {
      if (!acc.some((x) => x.label === el.label)) acc.push(el)

      return acc
    }, [])
  }

  const getCategoryOptions = (categoriesItems) => {
    const { gender, age } = filterValues
    const categoryOptions =
      gender !== GENDER_ALL_OPTION || age !== ALL_OPTION ? categoriesItems : categories

    return [
      ALL_OPTION,
      ...categoryOptions.map((c) => ({ ...c, value: c.id, label: c.name.trim() }))
    ].reduce((acc, el) => {
      if (!acc.some((x) => x.label === el.label)) acc.push(el)

      return acc
    }, [])
  }

  const handleChangeSwitch = async (e) => {
    const activeInMultipleDistances = e.target.checked

    const ConflictUpdateDistanceDialog =
      !activeInMultipleDistances &&
      distanceList.length &&
      categories.some(
        (c) => distanceList.filter((x) => x.categories.some((d) => d.id === c.id)).length > 1
      )

    if (ConflictUpdateDistanceDialog) {
      setOpenNotifyUpdateCategoriesDialog(true)
    } else {
      setFieldValue('allowCategoriesInMultipleDistances', activeInMultipleDistances)
    }
  }

  const onRemoveDistances = async () => {
    for (const { distance } of distanceList) {
      await dispatch(deleteDistance(eventId, distance.id))
    }

    setValues({ ...values, items: [], allowCategoriesInMultipleDistances: false })
    setOpenNotifyUpdateCategoriesDialog(false)
    await dispatch(loadDistances(eventId))
  }

  const getCategoryResults = () => {
    if (categories.length) {
      const { category, gender, age } = filterValues

      return categories.filter(
        (c) =>
          (category === ALL_OPTION || c.name === category.name) &&
          (gender === GENDER_ALL_OPTION || c.gender === gender.value) &&
          (age === ALL_OPTION || filterAge(age, c))
      )
    }
    return []
  }

  const filterAge = (optionSelected, currentCategory) => {
    if (optionSelected.ageNoLimit && currentCategory.ageNoLimit) return true

    if (
      optionSelected.ageRangeMin === currentCategory.ageRangeMin &&
      optionSelected.ageRangeMax === currentCategory.ageRangeMax
    )
      return true

    return false
  }

  const handleOnCreateDistance = () => {
    if (!categories.length) {
      setOpenConflictCreateDistanceDialog(true)
      return
    }

    setOpenCreateDistanceDialog(true)
  }

  const getGenderOption = (gender) => GENDER_LIST.find((x) => x.value === gender)

  const getRangeAgeLabel = (category) => {
    if (category.ageNoLimit) return 'Sin límite'

    return `${category.ageRangeMin} a ${category.ageRangeMax} años`
  }

  const formatOptionLabel =
    (field) =>
    ({ label }, { context }) => {
      if (context === 'value') return disabledFilter ? field : `${field} (${label})`

      if (context === 'menu') return label
    }

  const handleFilterChange = (field) => (option) =>
    setFilterValues({ ...filterValues, [field]: option })

  const results = getCategoryResults()
  const genders = getGenders(results)
  const ageList = getAgeList(results)

  const categoryOptions = getCategoryOptions(results)

  const handleFilterOptions = (list) => async (inputValue) =>
    list.filter((x) => x.label.toLowerCase().includes(inputValue.toLowerCase()))

  const handleDuplicateCategory = async (el, list) => {
    const categoriesName = list.map((c) => c.name)

    const copyCount = getCopyCount(categoriesName, el.name)
    const newDuplicateName = `Copia-${copyCount}-${el.name}`

    const duplicateEl = {
      ...el,
      shortName: el.shortName || '',
      name: newDuplicateName
    }

    const duplicateCategory = await dispatch(createCategory(eventId, duplicateEl))

    if (duplicateCategory) {
      setOpenCategorySuccessMessage(true)
      setOpenCategoryDuplicateDialog({
        [duplicateCategory.id]: true
      })
    }
  }

  const handleDuplicateDistance = async (el, list, e) => {
    const distancesName = list.map((d) => d.distance.name)

    const copyCount = getCopyCount(distancesName, el.distance.name)

    const newDuplicateName = `Copia-${copyCount}-${el.distance.name}`
    const distance = {
      ...el.distance,
      name: newDuplicateName
    }

    const returnModel = await dispatch(createDistance(eventId, distance))

    if (returnModel) {
      const newItems = el.categories.map((c) => ({
        eventCategory: c,
        eventCategoryId: c.id,
        eventDistance: returnModel,
        eventDistanceId: returnModel.id,
        quota: +c.quota
      }))

      setFieldValue('items', [...values.items, ...newItems])
      setOpenDistanceSuccessMessage(true)
      setOpenDistanceDuplicateDialog({
        [returnModel.id]: true
      })
    }
  }

  const getCopyCount = (list, name) => {
    if (!list.some((x) => x.includes(name) && x !== name)) return 1

    return list.reduce((acc, x) => {
      if (x.includes(name) && x !== name) {
        const { 0: key, 1: count = '' } = x.split('-')

        if (key === 'Copia' && count.match('^[0-9]+$') && +count > acc) return +count + 1
      }
      return acc
    }, 0)
  }

  const handleOnCloseDistanceDialog = () => setOpenDistanceDuplicateDialog({})

  const handleOnCloseCategoryDialog = () => setOpenCategoryDuplicateDialog({})

  const handleExportCategories = () => dispatch(downloadEventCategories(event))

  return (
    <>
      <SectionBlock
        title='1 Categorías*'
        activeAlertChange={values.formPublished && values.hasCategoriesChanges}
        body='Puedes crear las categorías de tu evento desde cero o reutilizar las de eventos anteriores,  editarlas y administrarlas.'>
        <div className={classes.headerSection}>
          <Typography className={clsx(classes.filterTitle, disabledFilter && 'disabled')}>
            Filtrar por
          </Typography>
          <div className={classes.filterContainer}>
            <StyledSelect
              name='category'
              colorOptions='primary'
              defaultOptions={categoryOptions}
              loadOptions={handleFilterOptions(categoryOptions)}
              value={filterValues.category}
              className={clsx(classes.select, disabledFilter && 'disabled')}
              isDisabled={disabledFilter}
              onChange={handleFilterChange('category')}
              formatOptionLabel={formatOptionLabel(disabledFilter ? 'Categoría' : 'Nombre')}
              cacheOptions
            />
            <StyledSelect
              name='gender'
              colorOptions='primary'
              defaultOptions={genders}
              value={filterValues.gender}
              loadOptions={handleFilterOptions(genders)}
              onChange={handleFilterChange('gender')}
              isDisabled={disabledFilter}
              className={clsx(classes.select, disabledFilter && 'disabled')}
              cacheOptions
              formatOptionLabel={formatOptionLabel('Género')}
            />
            <StyledSelect
              name='age'
              colorOptions='primary'
              defaultValue={!disabledFilter && ALL_OPTION}
              isDisabled={disabledFilter}
              defaultOptions={ageList}
              loadOptions={handleFilterOptions(ageList)}
              value={filterValues.age}
              onChange={handleFilterChange('age')}
              formatOptionLabel={formatOptionLabel('Edad')}
              className={clsx(classes.select, disabledFilter && 'disabled')}
              cacheOptions
            />
          </div>
        </div>

        <div className={classes.panel}>
          <table className={classes.table}>
            <thead>
              <tr className={classes.row}>
                <th className={clsx(classes.col, classes.nameCol)} title='Categorías'>
                  Categorías
                </th>
                <th className={classes.col} title='Abreviatura'>
                  Abreviatura
                </th>
                <th className={clsx(classes.col)} title='Género'>
                  Género
                </th>
                <th className={classes.col} title='Edad'>
                  Edad
                </th>
                <th className={clsx(classes.col, classes.actionCol)}></th>
              </tr>
            </thead>
            <tbody>
              {!!results.length &&
                results.map((item, index) => (
                  <tr key={index} className={classes.bodyRow}>
                    <td
                      className={clsx(classes.col, classes.nameCol, classes.ellipsisCol)}
                      title={item.name}>
                      {item.name}
                    </td>
                    <td className={clsx(classes.col, classes.ellipsisCol)} title={item.shortName}>
                      {item.shortName}
                    </td>
                    <td
                      className={clsx(classes.col, classes.genderCol)}
                      title={getGenderOption(item.gender).label}>
                      {getGenderOption(item.gender).key}
                    </td>
                    <td className={classes.col} title={getRangeAgeLabel(item)}>
                      {getRangeAgeLabel(item)}
                    </td>
                    <td className={clsx(classes.col, classes.actionCol)}>
                      <div className={classes.actionContainer}>
                        <EditCategoryDialog
                          field={item}
                          eventId={eventId}
                          setFieldValue={setFieldValue}
                          items={values.items}
                          showDialog={openCategoryDuplicateDialog[item.id] === true}
                          onClose={handleOnCloseCategoryDialog}
                          title={
                            openCategoryDuplicateDialog[item.id] === true && 'CATEGORÍA DUPLICADA'
                          }
                        />
                        <DeleteCategoryDialog
                          field={item}
                          eventId={eventId}
                          setFieldValue={setFieldValue}
                          distances={distanceList}
                          setItems={setFieldValue}
                          items={values.items}
                        />
                        <DuplicateButton
                          color='primary'
                          title='Duplicar categoría'
                          onDuplicate={() => handleDuplicateCategory(item, results)}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {!categories.length && (
            <div className={classes.emptyMessageContainer}>
              {categoriesPending ? (
                <CircularProgress size={30} color='primary' />
              ) : (
                <Typography className={classes.emptyMessage}>
                  Aún no tienes categorías en tu evento
                </Typography>
              )}
            </div>
          )}
          <SuccessMessage
            message='CATEGORÍA DUPLICADA CORRECTAMENTE'
            open={openCategorySuccessMessage}
            className={classes.messageContainer}
            onClose={() => setOpenCategorySuccessMessage(false)}
          />
        </div>

        <AddCategoryDialog
          open={openAddCategoryDialog}
          onClose={() => setOpenAddCategoryDialog(false)}
          onOpenCopyCategoryDialog={() => setOpenCopyCategoryDialog(true)}
          onOpenCreateCategoryDialog={() => setOpenCreateCategoryDialog(true)}
        />

        <CopyCategoryDialog
          open={openCopyCategoryDialog}
          formSetFieldValue={setFieldValue}
          onClose={() => setOpenCopyCategoryDialog(false)}
          categories={categories}
        />
        <CreateCategoryDialog
          open={openCreateCategoryDialog}
          formSetFieldValue={setFieldValue}
          onClose={() => setOpenCreateCategoryDialog(false)}
          eventId={eventId}
        />

        <div className={classes.actionButtonContainer}>
          <Button
            color='primary'
            variant='contained'
            className={classes.addCategoryButton}
            onClick={() => setOpenAddCategoryDialog(true)}>
            Agregar categorías
          </Button>

          {categories.length > 0 && (
            <Button
              color='primary'
              variant='outlined'
              disabled={downloadEventCategoriesPending}
              endIcon={
                downloadEventCategoriesPending && <CircularProgress size={16} color='primary' />
              }
              className={classes.addCategoryButton}
              onClick={handleExportCategories}>
              Exportar categorías
            </Button>
          )}
        </div>
      </SectionBlock>
      <SectionBlock
        title='2 Distancias*'
        body={
          <>
            Crea las distancias para tu evento, puedes editarlas y administrarlas cuando lo
            requieras.
            <br />
            <strong>*Esta información se visualizará en el perfil del evento.</strong>
          </>
        }
        activeAlertChange={
          values.formPublished &&
          (initialValues.allowCategoriesInMultipleDistances !==
            values.allowCategoriesInMultipleDistances ||
            initialValues.items
              .filter(
                (x) =>
                  values.hasCategoriesChanges || categories.some((c) => c.id === x.eventCategoryId)
              )
              .some((x) => !distances.some((d) => d.id === x.eventDistanceId)))
        }>
        <div className={classes.limitCategoryContainer}>
          <Typography color='primary' variant='h6'>
            Una misma categoría puede asociarse a más de una distancia
          </Typography>

          <StyledDistanceSwitch
            checked={values.allowCategoriesInMultipleDistances}
            className={classes.switch}
            onChange={handleChangeSwitch}
            name='switchDistanceInMultiple'
          />
          <ConfilctUpdateDistanceDialog
            open={openNotifyUpdateCategoriesDialog}
            onClose={() => setOpenNotifyUpdateCategoriesDialog(false)}
            onRemoveDistances={onRemoveDistances}
          />
        </div>
        <div className={classes.panel}>
          <table className={classes.table}>
            <thead>
              <tr className={classes.distanceRow}>
                <th className={clsx(classes.col, classes.indexCol)} title='N°'>
                  N°
                </th>
                <th className={clsx(classes.col, classes.distanceNameCol)} title='Nombre'>
                  Nombre
                </th>
                <th
                  className={clsx(classes.col, classes.totalCategories, classes.ellipsisCol)}
                  title='Cant. Categorías'>
                  Cant. Categorías
                </th>
                <th className={classes.col} title='Cupo'>
                  Cupo
                </th>
                <th className={clsx(classes.col, classes.actionCol)}></th>
              </tr>
            </thead>
            <tbody>
              {!!distanceList.length && (
                <>
                  {distanceList
                    .map((item) => ({ ...item, hasError: item.categories.length === 0 }))
                    .map((item, index) => (
                      <tr
                        key={index}
                        className={clsx(classes.bodyRow, item.hasError && classes.categoryError)}>
                        <td className={clsx(classes.col, classes.indexCol)}>{index + 1}</td>
                        <td
                          className={clsx(
                            classes.col,
                            classes.distanceNameCol,
                            classes.ellipsisCol
                          )}
                          title={item.distance.name}>
                          {item.distance.name}
                        </td>
                        <td
                          className={clsx(classes.col, classes.totalCategories)}
                          title={item.categories.length}>
                          {item.categories.length}
                        </td>
                        <td
                          className={classes.col}
                          title={item.totalQuotaByDistance || 'Sin límite'}>
                          {item.totalQuotaByDistance || 'Sin límite'}
                        </td>
                        <td className={clsx(classes.col, classes.actionCol)}>
                          <div className={classes.actionContainer}>
                            <EditDistanceDialog
                              eventId={eventId}
                              state={distanceState}
                              ticketTypes={values.ticketTypes}
                              items={values.items.filter((i) =>
                                distances.some((d) => d.id === i.eventDistance.id)
                              )}
                              asyncError={updateDistanceError}
                              allowCategoriesInMultipleDistances={
                                values.allowCategoriesInMultipleDistances
                              }
                              selectedDistance={item}
                              showDialog={openDistanceDuplicateDialog[item.distance.id] === true}
                              onClose={handleOnCloseDistanceDialog}
                              duplicateMode={openDistanceDuplicateDialog[item.distance.id] === true}
                            />
                            <DeleteDistanceDialog
                              eventId={eventId}
                              key={item.id}
                              formSetFieldValue={setFieldValue}
                              items={values.items}
                              selectedDistance={item}
                            />
                            <DuplicateButton
                              color='primary'
                              title='DISTANCIA DUPLICADA'
                              isDisabled={!values.allowCategoriesInMultipleDistances}
                              onDuplicate={(e) => handleDuplicateDistance(item, distanceList, e)}
                            />
                            {item.hasError && (
                              <CustomizedTooltip
                                title='Error Distancia incompleta'
                                arrow
                                autoOpenTooltip={{ state: true }}
                                position={isDesktop ? 'right-end' : 'top-end'}
                                className={classes.errorTicketTooltip}
                                arrowClassName={classes.errorTicketArrow}
                                buttonClassName={classes.closeButton}
                                disabledClickAway
                                popperClassName={classes.tooltipPopper}>
                                {({ handleTooltip }) => (
                                  <ErrorIcon
                                    className={classes.alertInfoIcon}
                                    onClick={handleTooltip}
                                  />
                                )}
                              </CustomizedTooltip>
                            )}

                            {values.formPublished && item.hasDistanceChanges && (
                              <CustomizedTooltip
                                position={isDesktop ? 'left-end' : 'top-end'}
                                className={classes.alertTooltip}
                                disabledClickAway
                                buttonClassName={classes.tooltipCloseButton}
                                focusedTooltipClassName={classes.tooltipPosition}
                                title='El cambio impactará al guardar los cambios.'>
                                {({ handleTooltip }) => (
                                  <IconButton
                                    size='small'
                                    className={classes.alertButton}
                                    onClick={handleTooltip}>
                                    <ErrorIcon />
                                  </IconButton>
                                )}
                              </CustomizedTooltip>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  <tr className={classes.resumeRow}>
                    <td></td>
                    <td className={classes.text}>TOTALES</td>
                    <td className={clsx(classes.col, classes.totalCategories)}>
                      {distanceListResume.totalCategoriesCount}
                    </td>
                    <td className={classes.col}>
                      {distanceListResume.totalQuota || 'Sin límite'}{' '}
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
          {!distanceList.length && (
            <div className={classes.emptyMessageContainer}>
              {distancesPending || activeInscriptionFormPending ? (
                <CircularProgress size={30} color='primary' />
              ) : (
                <Typography className={classes.emptyMessage}>
                  Aún no tienes distancias en tu evento
                </Typography>
              )}
            </div>
          )}
          <SuccessMessage
            message='DISTANCIA DUPLICADA CORRECTAMENTE'
            open={openDistanceSuccessMessage}
            onClose={() => setOpenDistanceSuccessMessage(false)}
          />
        </div>

        <CustomizedTooltip
          disabledTooltip={!disabledCreateDistance}
          arrow
          className={classes.tooltip}
          title='Para poder crear distancias debes crear nuevas categorías'
          position='right-end'>
          {({ handleTooltip, handleTooltipClose }) => (
            <div
              onMouseOver={handleTooltip}
              onMouseOut={handleTooltipClose}
              className={classes.createDistanceButtonContainer}>
              <Button
                color='primary'
                onClick={handleOnCreateDistance}
                variant='contained'
                disabled={disabledCreateDistance}
                className={classes.createDistanceButton}>
                Crear distancia
              </Button>
            </div>
          )}
        </CustomizedTooltip>
        <ConflictCreateDistanceDialog
          onClose={() => setOpenConflictCreateDistanceDialog(false)}
          open={openConflictCreateDistanceDialog}
        />
        <CreateDistanceDialog
          open={openCreateDistanceDialog}
          items={values.items.filter((i) => distances.some((d) => d.id === i.eventDistance.id))}
          eventId={eventId}
          distanceList={distanceList}
          asyncError={createDistanceError}
          allowCategoriesInMultipleDistances={values.allowCategoriesInMultipleDistances}
          onClose={() => setOpenCreateDistanceDialog(false)}
        />
      </SectionBlock>
    </>
  )
}

export default SectionCategoryAndDistance
