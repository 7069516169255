import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  listContainer: {
    position: 'relative',
    width: '100%',
    zIndex: 1,
    [theme.breakpoints.up('md')]: {
      width: 'calc(100% + 23vw)'
    },
    [theme.breakpoints.up('lg')]: {
      width: 'calc(100% + 305px)'
    }
  },
  cardContainer: {
    paddingLeft: theme.spacing(4),
    '& > div': {
      height: 160
    }
  },
  slickSlider: {
    '& > div > div': {
      marginLeft: 0
    },

    [theme.breakpoints.up('lg')]: {
      width: '1235px !important'
    }
  }
}))
