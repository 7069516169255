import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  containerHorizontal: {
    width: '100%',
    height: 2400,
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      height: 2600
    },
    [theme.breakpoints.up('lg')]: {
      margin: '0 auto',
      width: 1200
    }
  },
  title: {
    height: 20
  },
  select: {
    height: 37
  },
  text: {
    height: 16
  },
  field: {
    height: 33
  },
  dateField: {
    height: 33
  },
  multilineField: {
    height: 300
  },
  button: {
    height: 40
  }
}))
