import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Typography } from '@material-ui/core'
import clsx from 'clsx'
import { USER_ROLE } from 'utils/constants'

import { FollowedDialog } from '../FollowedDialog'
import { FollowedWidget } from '../FollowedWidget'
import { SectionBlock } from '../SectionBlock'
import { SectionFollowedPlaceholderLoader } from '../SectionFollowedPlaceholderLoader'

import { useStyle } from './SectionFollowed.style'

export const SectionFollowed = ({
  followedOrganizations,
  followedUserAccounts,
  myProfile,
  canEdit
}) => {
  const classes = useStyle()

  const { activeRole } = useSelector((state) => state.app)
  const { followedOrganizationsPending, followedUserAccountsPending } = useSelector(
    (state) => state.userAccounts
  )

  const [openFollowedDialog, setOpenFollowedDialog] = useState(false)

  const generateRamdomFollowed = (array, type, size) =>
    array
      ?.map((e) => ({ ...e, type }))
      .sort(() => 0.5 - Math.random())
      .slice(0, size)

  const followed = useMemo(() => {
    let followedResponse = []

    if (followedOrganizations && followedUserAccounts) {
      const followedOrganizationsSize =
        followedOrganizations.length > 3 ? 3 : followedOrganizations.length

      const followedUserAccountsSize =
        followedUserAccounts.length > 2 ? 2 : followedUserAccounts.length

      followedResponse = [
        ...generateRamdomFollowed(
          followedOrganizations,
          'organization',
          followedOrganizationsSize + (2 - followedUserAccountsSize)
        ),
        ...generateRamdomFollowed(
          followedUserAccounts,
          'relatedUserAccount',
          followedUserAccountsSize + (3 - followedOrganizationsSize)
        )
      ]
    }

    return followedResponse
  }, [followedOrganizations, followedUserAccounts])

  const followedCount =
    (Array.isArray(followedOrganizations) &&
      Array.isArray(followedUserAccounts) &&
      followedOrganizations.length + followedUserAccounts.length) ||
    0

  const handleCloseFollowedDialog = () => {
    setOpenFollowedDialog(false)
  }

  return (
    <SectionBlock
      title={`Seguidos (${followedCount})`}
      hiddenExpandedLink={canEdit && activeRole === USER_ROLE.ORGANIZER}
      sectionClassName={clsx(
        canEdit && activeRole === USER_ROLE.ORGANIZER ? classes.container : classes.section
      )}>
      {!followedOrganizationsPending && !followedUserAccountsPending ? (
        <>
          {followed &&
            followed.map((followed) => {
              const { type, id } = followed
              return <FollowedWidget key={id} followed={followed[type]} type={type} />
            })}
          {followedCount > 5 && (
            <>
              <Typography
                className={classes.linkFollowed}
                onClick={() => {
                  setOpenFollowedDialog(true)
                }}>
                ver todos los seguidos
              </Typography>
              <FollowedDialog
                open={openFollowedDialog}
                myProfile={myProfile}
                handleClose={handleCloseFollowedDialog}
                followedOrganizations={followedOrganizations}
                followedUserAccounts={followedUserAccounts}
              />
            </>
          )}
        </>
      ) : (
        <SectionFollowedPlaceholderLoader />
      )}
    </SectionBlock>
  )
}

export default SectionFollowed
