import React, { useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core'
import { Add as AddIcon, Remove as RemoveIcon } from '@material-ui/icons'
import clsx from 'clsx'

import { useStyles } from './QuestionBlock.style'

const QuestionBlock = ({ question, response, className }) => {
  const classes = useStyles()

  const [expanded, setExpanded] = useState(false)

  const handleChange = () => setExpanded(!expanded)

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      className={clsx(classes.accordion, className)}>
      <AccordionSummary
        expandIcon={!expanded ? <AddIcon /> : <RemoveIcon />}
        aria-controls='question-content'
        id='question-header'
        className={classes.headerContainer}>
        <Typography className={classes.heading}>{question}</Typography>
      </AccordionSummary>

      <AccordionDetails className={classes.details}>
        <Typography className={classes.response}>{response}</Typography>
      </AccordionDetails>
    </Accordion>
  )
}

export default QuestionBlock
