import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 700,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    fontSize: '1.125rem'
  },
  expandedSection: {
    [theme.breakpoints.up('md')]: {
      width: 'calc(100% + 261px)'
    },

    [theme.breakpoints.up('lg')]: {
      width: 1230
    }
  }
}))
