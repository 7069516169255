import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  accordion: {
    boxShadow: 'none',
    borderBottom: '1px solid #888A8A',
    width: '100%',
    margin: 0
  },
  headerContainer: {
    height: 70,
    padding: theme.spacing(0, 1),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 2)
    }
  },
  heading: {
    fontWeight: 600,
    fontSize: '0.75rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '0.9375rem'
    }
  },
  details: {
    padding: theme.spacing(0, 2),
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(7),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 4),
      paddingTop: theme.spacing(),
      paddingBottom: theme.spacing(5)
    }
  },
  response: {
    width: '100%',
    fontSize: '0.75rem',
    fontWeight: 500,
    '& p': {
      fontWeight: 500,
      fontSize: '0.75rem'
    },
    [theme.breakpoints.up('md')]: {
      width: '85%',
      fontSize: '0.9375rem',
      '& p': {
        fontSize: '0.9375rem'
      }
    }
  }
}))
