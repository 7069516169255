import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  readMoreBox: {
    maxHeight: 170,
    overflow: 'hidden',
    position: 'relative'
  },
  readMoreBoxRevealed: {
    maxHeight: 'none',
    overflow: 'visible'
  },
  readMoreButtonContainer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    margin: 0,
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(5),
    backgroundImage: 'linear-gradient(to bottom, transparent, white)',
    '& button': {
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      fontSize: '1.125em',
      width: '100%',
      border: 'none',
      background: 'none',
      color: theme.palette.primary.main,
      textAlign: 'left',
      textDecoration: 'underline'
    }
  },
  sectionContainer: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    backgroundColor: theme.palette.background.default,
    borderRadius: 20,

    '& h3': {
      fontWeight: 600,
      fontSize: '1.25em',
      marginTop: theme.spacing(),
      marginBottom: theme.spacing(),
      color: theme.palette.primary.main
    },

    [theme.breakpoints.up('md')]: {
      borderBottom: '1px solid #A9ABAE',
      borderRadius: 0,
      backgroundColor: 'transparent'
    },

    '& > p': {
      color: theme.palette.primary.main
    }
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing()
  },
  button: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: theme.palette.primary.contrastText
  }
}))

export default useStyles
