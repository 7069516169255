import React, { useEffect, useState } from 'react'
import { scroller } from 'react-scroll'
import { MainLayout, PageContainer } from 'shared'
import { ANALYTICS_EVENTS } from 'utils/constants'
import { trackEvent } from 'utils/functions'
import { useQuery } from 'utils/hooks'

import {
  ChooseOptionSection,
  CoverSection,
  CreateMultidisciplinarySection,
  CustomFormSection,
  EventTimingSection,
  FrequentQuestionsSection,
  GeneratorProfileSection,
  ManageEventSection,
  MonitorSection,
  OrganizersUsSection,
  PublishEventSection,
  QueriesSection,
  UsSection
} from './components'
import { useStyles } from './OrganizationPage.style'

const OrganizationPage = () => {
  const classes = useStyles()
  const query = useQuery()

  const section = query.get('section')

  const [activeAnimation, setActiveAnimation] = useState({})

  useEffect(() => {
    trackEvent(ANALYTICS_EVENTS.ACCESS.LANDING_ORGANIZATION)
  }, [])

  useEffect(() => {
    if (section) scrollToNextSection()
  }, [section])

  const endAnimationAction = () => setActiveAnimation({})

  const scrollToNextSection = () => {
    scroller.scrollTo(section, {
      duration: 1000,
      delay: 0,
      smooth: 'easeInOutQuad'
    })

    setActiveAnimation({
      [section]: true
    })
  }

  return (
    <MainLayout>
      <PageContainer customContainerClassName={classes.container}>
        <CoverSection
          id='coverSection'
          activeAnimation={!!activeAnimation.coverSection}
          endAnimationAction={endAnimationAction}
          onActiveAnimation={() => setActiveAnimation({ manageEventSection: true })}
        />

        <ManageEventSection
          id='manageEventSection'
          activeAnimation={!!activeAnimation.manageEventSection}
          endAnimationAction={endAnimationAction}
          setActiveAnimation={setActiveAnimation}
          onActiveAnimation={() => setActiveAnimation({ publishEventsSection: true })}
        />

        <PublishEventSection
          id='publishEventsSection'
          activeAnimation={!!activeAnimation.publishEventsSection}
          onActiveAnimation={() => setActiveAnimation({ usSection: true })}
        />

        <UsSection
          id='usSection'
          activeAnimation={!!activeAnimation.usSection}
          endAnimationAction={endAnimationAction}
          onActiveAnimation={() => setActiveAnimation({ customFormSection: true })}
        />

        <CustomFormSection
          id='customFormSection'
          activeAnimation={!!activeAnimation.customFormSection}
          onActiveAnimation={() => setActiveAnimation({ chooseOptionSection: true })}
        />

        <ChooseOptionSection
          id='chooseOptionSection'
          activeAnimation={!!activeAnimation.chooseOptionSection}
          endAnimationAction={endAnimationAction}
          onActiveAnimation={() => setActiveAnimation({ monitorSection: true })}
        />

        <MonitorSection
          id='monitorSection'
          activeAnimation={!!activeAnimation.monitorSection}
          onActiveAnimation={() => setActiveAnimation({ eventTimingSection: true })}
        />

        <EventTimingSection
          id='eventTimingSection'
          activeAnimation={!!activeAnimation.eventTimingSection}
          endAnimationAction={endAnimationAction}
          onActiveAnimation={() => setActiveAnimation({ chooseOptionSection: true })}
        />

        <GeneratorProfileSection
          id='generatorProfileSection'
          activeAnimation={!!activeAnimation.generatorProfileSection}
          onActiveAnimation={() => setActiveAnimation({ organizersUsSection: true })}
        />

        <OrganizersUsSection
          id='organizersUsSection'
          activeAnimation={!!activeAnimation.organizersUsSection}
          endAnimationAction={endAnimationAction}
          onActiveAnimation={() => setActiveAnimation({ createMultidisciplinarySection: true })}
        />

        <CreateMultidisciplinarySection
          id='createMultidisciplinarySection'
          activeAnimation={!!activeAnimation.createMultidisciplinarySection}
          onActiveAnimation={() => setActiveAnimation({ frequentQuestionsSection: true })}
        />

        <FrequentQuestionsSection
          id='frequentQuestionsSection'
          activeAnimation={!!activeAnimation.frequentQuestionsSection}
          endAnimationAction={endAnimationAction}
          onActiveAnimation={() => setActiveAnimation({ queriesSection: true })}
        />

        <QueriesSection id='queriesSection' activeAnimation={!!activeAnimation.queriesSection} />
      </PageContainer>
    </MainLayout>
  )
}

export default OrganizationPage
