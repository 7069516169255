import React, { useRef } from 'react'
import Slider from 'react-slick'
import { OrganizationCard } from 'pages/Organizations/SearchPage/components'

import { useStyles } from './OrganizationContainer.style'

const OrganizationContainer = ({
  organizations,
  automaticMove = true,
  slidesToShow = 4,
  autoplay = true,
  autoplaySpeed = 3000,
  fetchNewPage = () => {}
}) => {
  const classes = useStyles()

  const slickRef = useRef(null)

  const settings = {
    className: classes.slickSlider,
    dots: false,
    arrows: false,
    infinite: automaticMove,
    speed: 500,
    slidesToShow,
    autoplaySpeed,
    autoplay,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ],
    onEdge: (direction) => {
      if (direction === 'left') {
        fetchNewPage()
      }
    }
  }

  return (
    <div className={classes.organizationContainer}>
      <div className={classes.listContainer}>
        <Slider {...settings} ref={slickRef}>
          {organizations.map((e) => (
            <div key={e.id} className={classes.cardContainer}>
              <OrganizationCard organization={e.organization} key={e.id} disabledAction />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}

export default OrganizationContainer
