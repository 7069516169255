import React from 'react'
import { Media } from 'react-breakpoints'
import ContentLoader from 'react-content-loader'

import { useStyles } from './PlaceholderLoader.style'

const PlaceholderLoader = () => {
  const classes = useStyles()
  const bgColor = '#ffffff'
  const fgColor = '#eeeeee'

  return (
    <Media>
      {({ breakpoints, currentBreakpoint }) =>
        breakpoints[currentBreakpoint] > breakpoints.md ? (
          <ContentLoader
            title='Cargando...'
            className={classes.containerHorizontal}
            backgroundColor={bgColor}
            foregroundColor={fgColor}>
            <rect
              x='35%'
              y='20'
              rx='5'
              ry='5'
              className={classes.title}
              width='30%'
              height='100%'
            />
            <rect
              x='25%'
              y='60'
              rx='5'
              ry='5'
              className={classes.title}
              width='50%'
              height='100%'
            />

            <rect
              x='20'
              y='120'
              rx='5'
              ry='5'
              className={classes.title}
              width='25%'
              height='100%'
            />

            <rect
              x='50'
              y='150'
              rx='10'
              ry='10'
              className={classes.select}
              width='30%'
              height='100%'
            />

            <rect
              x='70%'
              y='160'
              rx='5'
              ry='5'
              className={classes.text}
              width='30%'
              height='100%'
            />

            <rect
              x='20'
              y='250'
              rx='5'
              ry='5'
              width='25%'
              height='100%'
              className={classes.title}
            />

            <rect
              x='50'
              y='300'
              rx='5'
              ry='5'
              className={classes.field}
              width='30%'
              height='100%'
            />
            <rect
              x='70%'
              y='300'
              rx='10'
              ry='10'
              className={classes.select}
              width='30%'
              height='100%'
            />

            <rect
              x='50'
              y='380'
              rx='10'
              ry='10'
              className={classes.select}
              width='30%'
              height='100%'
            />

            <rect
              x='70%'
              y='370'
              rx='10'
              ry='10'
              className={classes.select}
              width='30%'
              height='100%'
            />

            <rect
              x='50'
              y='460'
              rx='5'
              ry='5'
              className={classes.field}
              width='30%'
              height='100%'
            />

            <rect x='60' y='500' rx='5' ry='5' className={classes.text} width='25%' height='100%' />

            <rect
              x='70%'
              y='450'
              rx='5'
              ry='5'
              className={classes.field}
              width='30%'
              height='100%'
            />

            <rect
              x='70%'
              y='500'
              rx='5'
              ry='5'
              className={classes.text}
              width='25%'
              height='100%'
            />

            <rect
              x='70%'
              y='550'
              rx='5'
              ry='5'
              className={classes.field}
              width='30%'
              height='100%'
            />

            <rect
              x='70%'
              y='600'
              rx='5'
              ry='5'
              className={classes.text}
              width='25%'
              height='100%'
            />

            <rect
              x='50'
              y='570'
              rx='5'
              ry='5'
              className={classes.dateField}
              width='12%'
              height='100%'
            />

            <rect
              x='260'
              y='570'
              rx='5'
              ry='5'
              className={classes.dateField}
              width='12%'
              height='100%'
            />

            <rect
              x='50'
              y='630'
              rx='5'
              ry='5'
              className={classes.dateField}
              width='12%'
              height='100%'
            />

            <rect
              x='260'
              y='630'
              rx='5'
              ry='5'
              className={classes.dateField}
              width='12%'
              height='100%'
            />

            <rect
              x='20'
              y='760'
              rx='5'
              ry='5'
              className={classes.title}
              width='25%'
              height='100%'
            />

            <rect
              x='20'
              y='800'
              rx='5'
              ry='5'
              className={classes.multilineField}
              width='100%'
              height='100%'
            />

            <rect
              x='20'
              y='1150'
              rx='5'
              ry='5'
              className={classes.title}
              width='25%'
              height='100%'
            />

            <rect
              x='20'
              y='1210'
              rx='5'
              ry='5'
              className={classes.field}
              width='100%'
              height='100%'
            />

            <rect
              x='20'
              y='1270'
              rx='10'
              ry='10'
              className={classes.button}
              width='17%'
              height='100%'
            />

            <rect
              x='20'
              y='1370'
              rx='5'
              ry='5'
              className={classes.title}
              width='25%'
              height='100%'
            />

            <rect
              x='20'
              y='1415'
              rx='5'
              ry='5'
              className={classes.multilineField}
              width='100%'
              height='100%'
            />

            <rect
              x='20'
              y='1750'
              rx='5'
              ry='5'
              className={classes.title}
              width='25%'
              height='100%'
            />

            <rect
              x='20'
              y='1800'
              rx='5'
              ry='5'
              className={classes.title}
              width='30%'
              height='100%'
            />

            <rect
              x='20'
              y='1835'
              rx='5'
              ry='5'
              className={classes.multilineField}
              width='100%'
              height='100%'
            />

            <rect
              x='20'
              y='2170'
              rx='5'
              ry='5'
              className={classes.title}
              width='30%'
              height='100%'
            />

            <rect
              x='20'
              y='2205'
              rx='5'
              ry='5'
              className={classes.multilineField}
              width='100%'
              height='100%'
            />

            <rect
              x='38%'
              y='2550'
              rx='10'
              ry='10'
              className={classes.button}
              width='22%'
              height='100%'
            />
          </ContentLoader>
        ) : (
          <ContentLoader
            title='Cargando...'
            className={classes.containerHorizontal}
            backgroundColor={bgColor}
            foregroundColor={fgColor}>
            <rect
              x='35%'
              y='20'
              rx='5'
              ry='5'
              className={classes.title}
              width='30%'
              height='100%'
            />
            <rect
              x='25%'
              y='60'
              rx='5'
              ry='5'
              className={classes.title}
              width='50%'
              height='100%'
            />

            <rect
              x='20'
              y='120'
              rx='5'
              ry='5'
              className={classes.title}
              width='90%'
              height='100%'
            />
            <rect
              x='20'
              y='150'
              rx='10'
              ry='10'
              className={classes.select}
              width='90%'
              height='100%'
            />
            <rect x='20' y='220' rx='5' ry='5' className={classes.text} width='90%' height='100%' />

            <rect
              x='20'
              y='300'
              rx='5'
              ry='5'
              width='60%'
              height='100%'
              className={classes.title}
            />

            <rect
              x='20'
              y='350'
              rx='5'
              ry='5'
              className={classes.field}
              width='90%'
              height='100%'
            />
            <rect
              x='20'
              y='420'
              rx='10'
              ry='10'
              className={classes.select}
              width='90%'
              height='100%'
            />
            <rect
              x='20'
              y='490'
              rx='5'
              ry='5'
              className={classes.field}
              width='90%'
              height='100%'
            />
            <rect x='20' y='540' rx='5' ry='5' className={classes.text} width='90%' height='100%' />
            <rect x='20' y='570' rx='5' ry='5' className={classes.text} width='60%' height='100%' />

            <rect
              x='20'
              y='630'
              rx='5'
              ry='5'
              className={classes.dateField}
              width='40%'
              height='100%'
            />
            <rect
              x='55%'
              y='630'
              rx='5'
              ry='5'
              className={classes.dateField}
              width='40%'
              height='100%'
            />

            <rect
              x='20'
              y='690'
              rx='5'
              ry='5'
              className={classes.dateField}
              width='40%'
              height='100%'
            />

            <rect
              x='55%'
              y='690'
              rx='5'
              ry='5'
              className={classes.dateField}
              width='40%'
              height='100%'
            />

            <rect
              x='20'
              y='760'
              rx='10'
              ry='10'
              className={classes.select}
              width='90%'
              height='100%'
            />
            <rect
              x='20'
              y='840'
              rx='10'
              ry='10'
              className={classes.select}
              width='90%'
              height='100%'
            />

            <rect
              x='20'
              y='920'
              rx='5'
              ry='5'
              className={classes.field}
              width='90%'
              height='100%'
            />
            <rect x='20' y='970' rx='5' ry='5' className={classes.text} width='70%' height='100%' />

            <rect
              x='20'
              y='1010'
              rx='5'
              ry='5'
              width='60%'
              height='100%'
              className={classes.title}
            />

            <rect
              x='20'
              y='1050'
              rx='5'
              ry='5'
              className={classes.multilineField}
              width='90%'
              height='100%'
            />

            <rect
              x='20'
              y='1400'
              rx='5'
              ry='5'
              width='60%'
              height='100%'
              className={classes.title}
            />

            <rect
              x='20'
              y='1440'
              rx='5'
              ry='5'
              className={classes.field}
              width='90%'
              height='100%'
            />

            <rect
              x='20'
              y='1500'
              rx='10'
              ry='10'
              className={classes.button}
              width='40%'
              height='100%'
            />

            <rect
              x='20'
              y='1600'
              rx='5'
              ry='5'
              width='60%'
              height='100%'
              className={classes.title}
            />

            <rect
              x='20'
              y='1650'
              rx='5'
              ry='5'
              className={classes.multilineField}
              width='90%'
              height='100%'
            />

            <rect
              x='20'
              y='1990'
              rx='5'
              ry='5'
              width='60%'
              height='100%'
              className={classes.title}
            />

            <rect
              x='20'
              y='2030'
              rx='5'
              ry='5'
              width='40%'
              height='100%'
              className={classes.text}
            />

            <rect
              x='20'
              y='2060'
              rx='5'
              ry='5'
              className={classes.multilineField}
              width='90%'
              height='100%'
            />

            <rect
              x='20'
              y='2400'
              rx='5'
              ry='5'
              width='40%'
              height='100%'
              className={classes.text}
            />
            <rect
              x='20'
              y='2430'
              rx='5'
              ry='5'
              className={classes.multilineField}
              width='90%'
              height='100%'
            />
          </ContentLoader>
        )
      }
    </Media>
  )
}

export default PlaceholderLoader
