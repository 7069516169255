export const ROUTES = {
  ROOT: '/',
  SIGN_IN: '/sign-in',
  SIGN_UP: '/sign-up',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  PRIVACY_POLICY: '/privacy-policy',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  ABOUT_US: '/about-us',
  PRODUCTS: '/products',

  EVENTS: {
    SEARCH: '/events/search',
    PROFILE: '/events/profile',
    EDIT: '/events/edit',
    CREATE: '/events/create',
    MY_EVENTS: '/events/my-events',
    MY_CREATED_EVENTS: '/events/my-created/all',
    MY_UPCOMING_EVENTS: '/events/my-upcoming/all',
    MY_LATEST_RESULT_EVENTS: '/events/my-results/all',
    UPCOMING_EVENTS: '/events/upcoming/all',
    LATEST_RESULTS_EVENTS: '/events/results/all',
    INSCRIPTION: '/events/inscription',
    INSCRIPTION_FORM: '/events/:slugUrlOrId/inscription',
    INSCRIPTION_LIST: '/events/:slugUrlOrId/list-inscription'
  },

  ORGANIZATIONS: {
    SEARCH: '/organizations/search',
    PROFILE: '/organizations/profile',
    CREATE: '/organizations/create',
    ONBOARDING: '/organizations/onboarding'
  },

  USERS_ACCOUNTS: {
    PROFILE: '/user-accounts/profile',
    SEARCH: '/user-accounts/search',
    FREQUENT_QUESTIONS: '/user-accounts/frequent-questions'
  },
  LANDING: {
    JOB: '/landing/job',
    TIMING: '/landing/timing',
    ORGANIZATION: '/landing/organization'
  }
}
