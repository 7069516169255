import React, { memo, useRef, useState } from 'react'
import { Cropper } from 'react-cropper'
import FileViewer, { DocViewerRenderers } from 'react-doc-viewer'
import { IconButton } from '@material-ui/core'
import { ZoomIn as ZoomInIcon, ZoomOut as ZoomOutIcon } from '@material-ui/icons'
import clsx from 'clsx'

import { useStyles } from './FileViewerContent.style'

const IMAGE_EXTENSION = {
  PNG: '.png',
  JPG: '.jpg',
  JPEG: '.jpeg',
  GIF: '.gif'
}

const ZOOM_INITIAL_VALUE = 0

const FileViewerContent = ({
  fileExtension = '',
  fileUrl,
  cropperClassName,
  fileViewerClassName
}) => {
  const classes = useStyles()
  const cropperRef = useRef(null)

  const [zoomLevel, setZoomLevel] = useState(ZOOM_INITIAL_VALUE)

  const isImageFile = IMAGE_EXTENSION[fileExtension.toUpperCase()]

  const renderViewFile = () => {
    if (!fileUrl) return <div className={classes.notFoundFileContainer} />

    const files = [{ uri: fileUrl, fileType: fileExtension }]

    return (
      <div className={classes.fileContainer}>
        <FileViewer
          documents={files}
          pluginRenderers={DocViewerRenderers}
          className={clsx(classes.fileViewer, fileViewerClassName)}
          config={{
            header: {
              disableHeader: true,
              disableFileName: true
            }
          }}
        />
      </div>
    )
  }

  const renderViewImage = () => {
    const handleZoomIn = () => {
      const cropper = cropperRef.current?.cropper
      if (!cropper) return

      cropper.zoom(0.1)
      setZoomLevel((state) => state + 1)
    }

    const handleZoomOut = () => {
      const cropper = cropperRef.current?.cropper
      if (!cropper) return

      cropper.zoom(-0.1)
      setZoomLevel((state) => (state > ZOOM_INITIAL_VALUE ? state - 1 : ZOOM_INITIAL_VALUE))
    }

    return (
      <div className={classes.cropperContainer}>
        <Cropper
          src={fileUrl}
          highlight={false}
          viewMode={1}
          ref={cropperRef}
          className={clsx(
            classes.crooper,
            cropperClassName,
            zoomLevel === ZOOM_INITIAL_VALUE && 'normal'
          )}
          guides={false}
          background={false}
          dragMode='move'
          autoCropArea={1}
          modal={true}
          cropBoxResizable={false}
          cropBoxMovable={false}
          toggleDragModeOnDblclick={false}
          zoomOnWheel={false}
        />

        <div className={classes.buttonContainer}>
          <IconButton onClick={handleZoomIn} color='primary'>
            <ZoomInIcon className={classes.zoomIcon} />
          </IconButton>
          <IconButton
            onClick={handleZoomOut}
            color='primary'
            disabled={zoomLevel === ZOOM_INITIAL_VALUE}>
            <ZoomOutIcon className={classes.zoomIcon} />
          </IconButton>
        </div>
      </div>
    )
  }

  return isImageFile ? renderViewImage() : renderViewFile()
}

export default memo(FileViewerContent)
